

export default {
    'sales description': 
    <p>
      <p className="flex flex-start m-b-20 fz-18">
      Amount
      </p>
      <p className="flex flex-start m-b-20 fz-14 c06">
          <div style={{"width": "30px", "font-weight":"bold", "text-align":"center", "font-size":"30px", "line-height":"14px"}}>·</div>
          <div className="flex-1">
          A total amount of 2160 single nft are supplied. Each mecha suit is composed of 6 single nft that contain different mecha parts.<br/>
          </div>
      </p>
      <p className="flex flex-start m-b-20 fz-18">
      Feature
      </p>
      <p className="flex flex-start m-b-20 fz-14 c06">
          <div style={{"width": "30px", "font-weight":"bold", "text-align":"center", "font-size":"30px", "line-height":"14px"}}>·</div>
          <div className="flex-1">
          Players can only specify the gender while minting a mecha suit. For single mystery boxes, players will receive a random gender when opening the box. <br/>
          </div>
      </p>
      <p className="flex flex-start m-b-20 fz-14 c06">
          <div style={{"width": "30px", "font-weight":"bold", "text-align":"center", "font-size":"30px", "line-height":"14px"}}>·</div>
          <div className="flex-1">
          Each mecha suit contains 6 different mecha parts: helmet, body, gloves, wrist guards, leg guards, and different parts of the shoes. <br/>
          </div>
      </p>
      <p className="flex flex-start m-b-20 fz-14 c06">
          <div style={{"width": "30px", "font-weight":"bold", "text-align":"center", "font-size":"30px", "line-height":"14px"}}>·</div>
          <div className="flex-1">
          The colors of the mecha suit include red, orange, yellow, and green. <br/>
          </div>
      </p>
      <p className="flex flex-start m-b-20 fz-14 c06">
          <div style={{"width": "30px", "font-weight":"bold", "text-align":"center", "font-size":"30px", "line-height":"14px"}}>·</div>
          <div className="flex-1">
          The properties of the mecha suit include common(59.72%), rare(33.33%), epic(5.56%), and legendary(1.39%).<br/>
          </div>
      </p>
      <p className="flex flex-start m-b-20 fz-18">
      Price
      </p>
      <p className="flex flex-start m-b-20 fz-14 c06">
          <div style={{"width": "30px", "font-weight":"bold", "text-align":"center", "font-size":"30px", "line-height":"14px"}}>·</div>
          <div className="flex-1">
          The starting price is 1125USD. Whitelisted users all qualify for a 20% discount. The price increases every two hours rising to 2250USD in 90 days. 
          Price increases by 1.0417USD every two hours.<br/> </div>
      </p>
      
      <p className="flex flex-start m-b-20 fz-18">
      Qualification
      </p>
      <p className="flex flex-start m-b-20 fz-14 c06">
          <div style={{"width": "30px", "font-weight":"bold", "text-align":"center", "font-size":"30px", "line-height":"14px"}}>·</div>
          <div className="flex-1">
          The mystery boxes are only open to whitelisted players. <a href="https://nft-mint.kepler.homes/nft-whitelist" target="_blank" className="ce">Get whitelisted now. </a><br/>
          </div>
      </p>
    </p>,
    'roadmap': {
        'completed' :[
            {
                title: '2021 Q3',
                content: <div>
                    GameFi team building<br/>
                    Game story background written<br/>
                    Develop the economic models for the in the game ecosystem<br/>
                    Server-side architecture building
                </div>
            },
            {
                title: '2021 Q4',
                content: <div>
                    Kepler WebSite build<br/>
                    Multi-chain wallet, account and asset system construction<br/>
                    NFT Marketplace, Farm, Cross-chain and other application modules build<br/>
                    Client-side and server-side architecture<br/>
                    Casting the first batch of in-game NFTs<br/>
                    Game character art design<br/>
                    Game scene design and construction
                </div>
            },
            {
                title: '2022 Q1',
                content: <div>
                    Build the DAO, Community and other governance modules<br/>
                    Static rendering of characters and rendering of different presentation styles Development of character attribute functions<br/>
                    Development of game weapons, equipment, fashion and other items and accessories<br/>
                    Development of the game's combat system and skills<br/>
                    Recruitment of volunteers for in-game testing and the start of in-game testing<br/>
                </div>
            }

        ],
        'intend': [
            {
                title: '2022 Q2',
                isCurrent: true,
                content: <div>
                    Complete the design and development of NPC and scene related functions<br/>
                    Complete the design and development of NPCs and in game scenarios<br/>
                    Development of the game's homepage interface<br/>
                    Complete the development of the quest system<br/>
                    Release of the first alpha version of the game (Windows version)
                </div>
            },
            {
                title: '2022 Q3',
                content: <div>
                    Complete the first batch of NFT sales<br/>
                    Lands for sale module built<br/>
                    Completion of the KEPL Token release (Private and Public) and listing on the Dex<br/>
                    Build PGC (art experts content creation) module<br/>
                    Optimisation of Website applications and community governance modules in line with the game's progress<br/>
                    Release of the first public beta version of the game (Windows version)
                </div>
            },
            {
                title: '2022 Q4',
                content: <div>
                     List the KEPL Token on CEXes<br/>
                    Release the first batches of NFTs for Lands<br/>
                    Complete the Development of further instance zones of the game<br/>
                    Release of the first official MACOS V1 version of the game<br/>
                    Iterate the game, releasing an updated Windows version<br/>
                    PGC (art experts creating content) module building<br/>
                    Complete the development of the first instance zone of the game<br/>
                    Release of the official Windows V1 version of the game
                </div>
            }
        ]
    },
    'nft equity': 
      <p>
          <p className="">
            1.Priority whitelisting for various Kepler follow-up activities.
          </p>
          <p className="m-t-10">
            2.Priority access to the Kepler DAO Governance Committee.
          </p>
          <p className="m-t-10">
           3.Stake a Kepler Suit NFT to obtain KEPL Token airdrop rewards (details to be announced later).
          </p>
          
          <p className="m-t-10">
            4. Game equity: Kepler NFT holders will receive in-game equipment with the same attributes.
          </p>
          
          <p className="m-t-10">
           5. Further Kepler NFT entitlements will be added on an ongoing basis.
          </p>
          
          <p className="m-t-10">
          6.How to participate: 
          </p>
          
          <p>
          Participate in the Kepler Mystery Box sale and open the Mystery Box to get Kepler NFTs straight away:<a href="https://kepler.homes/nft-mint" className="ce">kepler.homes/nft-mint</a> 
          </p>
      </p>
}
    
