import {ProviderController} from 'web3modal';

import getNetworkData from '../helper/getNetworkData';

const CONNECT_EVENT = "connect";
const ERROR_EVENT = "error";

export const createProviderController = (network) => {
  const connectors = getNetworkData[`get${network}Network`]().connectors
  const providerController = new ProviderController(connectors)

  return {
    connect: () => {
      return new Promise(async (resolve, reject) => {
        providerController.on(CONNECT_EVENT, provider => {
          resolve(provider)
          if (!provider) {
            console.log('clearCachedProvider')
            setTimeout(() => {
              providerController.clearCachedProvider()
            }, 0)
          }
        });
        providerController.on(ERROR_EVENT, error => reject(error));
        if (providerController.cachedProvider) {
          await providerController.connectToCachedProvider();
        }
      });
    },
    connectTo: (id) => {
      return new Promise(async (resolve, reject) => {
        providerController.on(CONNECT_EVENT, provider => {
          resolve(provider)
          if (!provider) {
            console.log('clearCachedProvider')
            setTimeout(() => {
              providerController.clearCachedProvider()
            }, 0)
          }
        });
        providerController.on(ERROR_EVENT, error => reject(error));
        const provider = providerController.getProvider(id);
        if (!provider) {
          return reject(
            new Error(
              `Cannot connect to provider (${id}), check provider options`
            )
          );
        }
        await providerController.connectTo(provider.id, provider.connector);
      });
    },
    cachedProvider: () => {
      return providerController.cachedProvider
    },
    clearCachedProvider: () => {
      providerController.clearCachedProvider()
    }
  }
}
