export default [
    {
      name: '2021 Q3-Q4',
      complete:true,
      content: <p>GameFi team building<br/>
      Game story background written<br/>
      Develop the economic models for the in the game ecosystem<br/>
      Server-side architecture building<br/>
      Kepler WebSite build<br/>
      Multi-chain wallet, account and asset system construction<br/>
      NFT Marketplace, Farm, Cross-chain and other application modules build<br/>
      Client-side architecture<br/>
      Casting the first batch of in-game NFTs<br/>
      Game character art design</p>
    },
    {
      name: '2022 Q1-Q2',
      complete:true,
      content: <p>Static rendering of characters and rendering of different presentation styles Development of character attribute functions<br/>
      Development of game weapons, equipment, fashion and other items and accessories<br/>
      Development of the game's combat system and skills<br/>
      Complete the design and development of NPC and scene related functions<br/>
      Complete the design and development of NPCs and in game scenarios<br/>
      Development of the game's homepage interface</p>
    },
    {
      name: '2022 Q3-Q4',
      complete:true,
      content: <p>Complete the development of the quest system<br/>
      Completed the beta version V1<br/>
      Completed the beta version V2<br/>
      Completed the beta version V3<br/>
      Building the Kepler Game Map M3<br/>
      Added new gameplay prototyping<br/>
      Development and testing completed Aptos chain related modules: MarketPlace, Passport</p>
    },
    {
      name: '2023 Q1-Q2',
      complete:true,
      content: <p>Increase the deployment of Kepler Daap to the Arbitrum ecosystem and carry out large-scale publicity<br/>
      Complete the Development of further instance zones of the game<br/>
      Updated game character prototype settings and new game occupations<br/>
      Increase game combat details and combat effects<br/>
      Complete the game PvP combat system design, matching system, numerical design<br/>
      Add new monster characters and dungeon tasks to the game<br/>
      Improve and optimize the computer configuration requirements of the game engine<br/>
      Planning the open world land gameplay of the Kepler game<br/>
      Complete the Arbitrum ecological Passport issuance plan<br/>
      Complete the first batch of NFT sales (game set)<br/>
      Completed the second batch of NFT sales (game fashion)<br/>
      In-Game NFT Brand Partnerships</p>
    },
    {
      name: '2023 Q3-Q4',
      content: <p>Start prototyping/front-end development/contract development for Social@KeplerHomes<br/>
      Complete the testing, launch and marketing of Social@KeplerHomes<br/>
      Conduct community survey activities to collect feedback and suggestions from the community<br/>
      Base and Social launch a lightweight on-chain game (crypto farm)<br/>
      Set Social@KeplerHomes operational goals and continue to optimize Social products</p>
    },
    {
      name: '2024 Q1-Q2',
      content: <p>Completed Arbitrum ecological token issuance plan<br/>
      In-game NFT brand partners<br/>
      List KEPL Token on Dex<br/>
      Kepler and Social@KeplerHomes have launched a total of 2 lightweight on-chain games<br/>
      Complete the development of more instance areas of the game<br/>
      Updated game character prototype settings and new game professions<br/>
      Increase game combat details and combat effects<br/>
      Complete the game PvP combat system design, matching system, and numerical design<br/>
      Add new monster characters and dungeon quests to the game</p>
    }
]