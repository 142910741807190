import React, { useState, useEffect, useRef } from 'react';
// import Tooltip from 'rc-tooltip'
// import 'rc-tooltip/assets/bootstrap.css'
import iconMenu from '../../assets/images/home/icon-menu.svg'
import { Menu, Dropdown, Tooltip } from 'antd';
import iconArrow from '../../assets/images/home/icon-arrow.svg'
import logo from '../../assets/images/home/logo.svg'
import iconProfile from '../../assets/images/home/icon-profile.svg'
import iconLanguage from '../../assets/images/home/icon-language.svg'
import iconDownload from '../../assets/images/home/icon-download.png'
import arrow from '../../assets/images/header/arrow.svg'
import eth from '../../assets/images/ConnectWallet/ethereum.png'
import binance from '../../assets/images/ConnectWallet/binance.png'
import base from '../../assets/images/ConnectWallet/BASE.svg'
import arbitrum from '../../assets/images/ConnectWallet/arbitrum.jpg'
import aptos from '../../assets/images/ConnectWallet/Aptos.png'
import classnames from 'classnames'
import './Header.scss'
import { useTranslation} from 'react-i18next'
import {connect, useSelector} from 'react-redux'
import ConnectWallet from '../ConnectWallet';
import Login from '../Login';
import {NavLink, Link} from 'react-router-dom'
import MobileMenu from './Menu'
import Bus from '../../lib/eventBus'
import { showLogin } from '../../lib/util'
import keplerX from '../../assets/images/passport/white/keplerx.png'
import lucky from '../../assets/images/passport/white/lucky.svg'
import luckyb from '../../assets/images/passport/white/luckyb.svg'
import og from '../../assets/images/passport/white/og.png'
import WhiteType from './WhiteType';
import { get } from '../../http';
let whiteimg = {
  '1':keplerX,
  '2': lucky,
  '3': og,
  '4': og
}
let whiteimgmodal = {
  '1':keplerX,
  '2': luckyb,
  '3': og,
  '4': og
}

function Header(props) {
  let { t ,i18n} = useTranslation()
  let language = i18n.language.split('-')[0]
  const [showMobileMenu, setShowMobileMenu] = useState(false)

  let [whiteType, setWhiteType] = useState(false)
  let marketlinks = [
    {
      title: 'Passport Mint',
      link: 'https://passport.kepler.homes',
      isoutlink: true,
      desc: 'Get your first Kepler metaverse NFT',
      disabled: true,
      icon: require('../../assets/images/header/origin.svg').default
    },
    {
      title: 'NFT Marketplace',
      link: 'https://market.kepler.homes',
      isoutlink: true,
      desc: 'Kepler AirdropBox trade market',
      disabled: false,
      icon: require('../../assets/images/header/marketplace.svg').default
    },
    {
      title: 'Lands',
      link: '/lands',
      desc: 'Mint your land on planet Kepler',
      disabled: true,
      icon: require('../../assets/images/header/lands.svg').default
    },
    {
      title: 'My NFT',
      link: 'https://market.kepler.homes/mynft',
      isoutlink: true,
      desc: 'Manage my NFTs',
      disabled: false,
      icon: require('../../assets/images/header/mynft.svg').default
    }
  ]

  let exchangeLinks = [
    {
      title: 'Uniswap',
      link: 'https://app.uniswap.org/#/swap',
      desc: 'Coming soon',
      disabled: true,
      icon: require('../../assets/images/header/uniswap.svg').default
    },
    {
      title: 'Pancake',
      link: 'https://pancakeswap.finance/',
      desc: 'Coming soon',
      disabled: true,
      icon: require('../../assets/images/header/pancake.svg').default
    },
    {
      title: 'Serum',
      link: 'https://portal.projectserum.com',
      desc: 'Coming soon',
      disabled: true,
      icon: require('../../assets/images/header/serum.svg').default
    },
    {
      title: 'TraderJoe',
      link: 'https://www.traderjoexyz.com/',
      desc: 'Coming soon',
      disabled: true,
      icon: require('../../assets/images/header/traderjoe.png')
    },
    {
      title: 'Paraswap',
      link: 'https://paraswap.io/#/?network=polygon',
      desc: 'Coming soon',
      disabled: true,
      icon: require('../../assets/images/header/paraswap.svg').default
    }
  ]
  let airdropList = [{
    title: 'ETH Airdrop',
    link: 'https://eth-claim.kepler.homes',
    icon: eth
  },
    {
      title: 'Arbitrum Airdrop',
      link: 'https://arb-claim1.kepler.homes',
      icon: arbitrum
    },{
      title: 'BSC Airdrop',
      link: 'https://claim-bsc.kepler.homes',
      icon: binance
    },{
      title: 'Base Airdrop',
      link: 'https://base-claim1.kepler.homes',
      icon: base
    },
  ]
  let presaleList = [
    {
      title: 'Arbitrum Presale',
      link: 'https://presale-arbitrum.kepler.homes',
      icon: arbitrum
    },{
      title: 'BSC Presale',
      link: 'https://pre-bsc.kepler.homes',
      icon: binance
    }
  ]
  let whitelistList = [{
    title: 'ETH Allowlist',
    link: 'https://allowlist.kepler.homes/?chain=ETH',
    icon: eth
  },
    {
      title: 'Arbitrum Allowlist',
      link: 'https://allowlist.kepler.homes/?chain=ARB',
      icon: arbitrum
    },{
      title: 'BSC Allowlist',
      link: 'https://allowlist.kepler.homes/?chain=BSC',
      icon: binance
    },{
      title: 'Aptos Allowlist',
      link: 'https://passport-aptos.kepler.homes',
      icon: aptos
    },
  ]
  let farmlinks = [
    {
      title: 'Overview',
      link: '/farm/overview',
      desc: 'Coming soon',
      disabled: true,
      icon: require('../../assets/images/header/dashboard.svg').default
    },{
      title: 'Farm',
      link: '/farm/pools',
      desc: 'Coming soon',
      disabled: true,
      icon: require('../../assets/images/header/farm.svg').default
    },{
      title: 'Rewards',
      link: '/farm/claim',
      desc: 'Coming soon',
      disabled: true,
      icon: require('../../assets/images/header/rewards.svg').default
    }
  ]
  let communitylinks = [
    {
      title: 'Blog',
      link: 'https://medium.com/@KeplerHomes',
      isoutlink: true,
      icon: require('../../assets/images/header/blog.svg').default
    },
    {
      title: 'Discord',
      link: 'https://discord.gg/tFCMng5Mhd',
      isoutlink: true,
      icon: require('../../assets/images/header/discord.svg').default
    },{
      title: 'Telegram',
      link: 'https://t.me/KeplerHomes',
      isoutlink: true,
      icon: require('../../assets/images/header/telegram.svg').default
    },{
      title: 'Twitter',
      link: 'https://twitter.com/KeplerHomes',
      isoutlink: true,
      icon: require('../../assets/images/header/twitter.svg').default
    },{
      title: 'YouTube',
      link: 'https://www.youtube.com/@keplerhomes',
      isoutlink: true,
      icon: require('../../assets/images/header/youtube.svg').default
    },{
      title: 'Apply For Volunteer',
      link: '/apply-volunteer',
      // desc: 'Coming soon',
      disabled: false,
      icon: require('../../assets/images/header/volunteer.svg').default
    },{
      title: 'Apply For Partner',
      link: '/apply-partner',
      // desc: 'Coming soon',
      disabled: false,
      icon: require('../../assets/images/header/partner.svg').default
    },
  ]
  let morelinks = [ 
    {
      title: 'FAQ',
      link: '/faq',
      // desc: 'Coming soon',
      disabled: false,
      icon: require('../../assets/images/header/faq.svg').default
    },{
      title: 'Docs',
      isoutlink: true,
      link: 'https://docs.kepler.homes/',
      icon: require('../../assets/images/header/docs.svg').default
    },{
      title: 'News',
      link: '/news',
      // desc: 'Coming soon',
      disabled: false,
      icon: require('../../assets/images/header/news.svg').default
    },
    // {
    //   title: 'Claim',
    //   link: '/',
    //   desc: 'Coming soon',
    //   disabled: true,
    //   icon: require('../../assets/images/header/claim.svg').default
    // },
    // {
    //   title: 'Tokenomics',
    //   link: '/tokenomics',
    //   disabled: false,
    //   icon: require('../../assets/images/header/tokenomics.svg').default
    // },
    // {
    //   title: 'NFT-Mint',
    //   isoutlink: true,
    //   link: 'https://nft-mint.kepler.homes',
    //   // icon: require('../../assets/images/header/docs.svg').default
    //   },
      // {
      //   title: 'NFT-Whitelist',
      //   isoutlink: true,
      //   link: 'https://nft-mint.kepler.homes/nft-whitelist',
      //   // icon: require('../../assets/images/header/docs.svg').default
      // },
      {
      title: 'Bridge',
      link: '/tokenomics',

      desc: 'Coming soon',
      disabled: true,
    },{
      title: 'DAO',
      link: '/tokenomics',

      desc: 'Coming soon',
      disabled: true,
    },{
      title: 'Claim',
      link: '/tokenomics',

      desc: 'Coming soon',
      disabled: true,
    }
  ]

  // const [hasBg, setHasBg] = useState(false)

  // useEffect(() => {
  //   function scroll() {
  //     const scrollHeight = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
  //   }
  //   window.addEventListener('scroll', scroll)
  //   return () => {
  //     window.removeEventListener('scroll', scroll)
  //   }
  // }, [])

  useEffect(() => {
    window.addEventListener('click', function() {
      setShowMobileMenu(false)
    })
    Bus.addListener('foldChange', (isfold) => {
      setShowMobileMenu(isfold)
  });
  }, [])
   
  let [isFold, setFold] = useState(true)


  useEffect(async()=>{
    try {
      if(props.account) {
        get('/api/passport/buyParams', {
           address: props.account
        }).then(res => {
          setWhiteType(res.data.wl_type)
        }).catch(err => {
          setWhiteType(0)
        })
      } else {
        setWhiteType(0)
      }
    }catch(err) {
      setWhiteType(0)
    }
    
  }, [props.account, props.isApply])

  let foldfn = () => {
      Bus.emit('foldChange', !isFold);
  }
  
  useEffect(() => {
      Bus.addListener('foldChange', (isfold) => {
          setFold(isfold)
      });
  }, [])

  return (
    <>
    <div className={classnames(["global-header", {"has-bg": props.hasBg}])}>
      <NavLink className="logo" to="/">
        <img src={logo} alt="" />
      </NavLink>
      <div className="nav-list">
      <div className="nav-item active">

      {/* <a className="" href="https://kepler.im" target='_blank'>
      Kepler.IM
              </a> */}
              </div>
        <div className="nav-item active">
          {/* <Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
            <Menu>
              {
                marketlinks.map((item, index) => {
                  return (<Menu.Item key={index} disabled={item.disabled}>
                    {
                      item.isoutlink ? 
                        <a href={item.link} target="_blank">
                          <img src={item.icon}  alt={item.title} />
                          <div className="menu-wrapper m-r-50">
                            <div className="menu-title">{item.title}
                            {
                          item.disabled ?<span className="comingsoon"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>:''
                        }</div>
                            <div className="menu-title-sub">{item.desc}</div>
                          </div>
                          <div className="arrow">
                            <img src={arrow} alt="" />
                          </div>
                        </a>:
                        <NavLink to={item.link}>
                      <img src={item.icon} alt={item.title} />
                      <div className="menu-wrapper m-r-50">
                        <div className="menu-title" style={{"width": "220px"}}>{item.title}
                        {
                          item.disabled ?<span className="comingsoon"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>:''
                        }
                        </div>
                        <div className="menu-title-sub">{item.desc}</div>
                      </div>
                      <div className="arrow">
                        <img src={arrow} alt="" />
                      </div>
                    </NavLink>
                    }
                    
                  </Menu.Item>)
                })
              }
            </Menu>
          }> */}
            <a className="" href="https://market.kepler.homes" target='_blank'>
              Market
               {/* <img className="icon-arrow" src={iconArrow} alt="" /> */}
            </a>
          {/* </Dropdown> */}
        </div>

        <div className="nav-item">
          <Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
            <Menu>
              {
                communitylinks.map((item, index) => {
                  return (<Menu.Item key={index} disabled={item.disabled}>
                    {
                      item.needLogin && !props.token ? (
                            <a onClick={showLogin} href="javascript:;">
                            <img src={item.icon}  alt={item.title} />
                            <div className="menu-wrapper m-r-50">
                              <div className="menu-title">{item.title}</div>
                              <div className="menu-title-sub">{item.desc}</div>
                            </div>
                            <div className="arrow">
                              <img src={arrow} alt="" />
                            </div>
                          </a>
                      ):(
                        item.isoutlink ? (
                          <a href={item.link} target="_blank">
                            <img src={item.icon}  alt={item.title} />
                            <div className="menu-wrapper m-r-50">
                              <div className="menu-title">{item.title}
                              {
                                item.disabled ?
                                <span className="comingsoon"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>
                                :''
                              }
                              
                              </div>
                              {/* <div className="menu-title-sub">{item.desc}</div> */}
                            </div>
                            <div className="arrow">
                              <img src={arrow} alt="" />
                            </div>
                          </a>
                        ):(
                          <NavLink to={item.link}>
                            <img src={item.icon}  alt={item.title} />
                            <div className="menu-wrapper m-r-50">
                              <div className="menu-title">{item.title}
                              {
                                item.disabled ?
                                <span className="comingsoon"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>
                                :''
                              }
                              </div>
                              {/* <div className="menu-title-sub">{item.desc}</div> */}
                            </div>
                            <div className="arrow">
                              <img src={arrow} alt="" />
                            </div>
                          </NavLink>
                        )
                      )
                      
                    }
                    
                  </Menu.Item>)
                })
              }
            </Menu>
          }>
            <a className="" onClick={e => e.preventDefault()}>
              Community <img className="icon-arrow" src={iconArrow} alt="" />
            </a>
          </Dropdown>
        </div>
        <div className="nav-item">
        <Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
            <Menu>
              {
                airdropList.map((item, index) => {
                  return (<Menu.Item key={index} disabled={item.disabled}>
                    <a href={item.link} target="_blank">
                      <img src={item.icon} alt={item.title} style={{width: '32px'}}/>
                      <div className="menu-wrapper m-r-50 flex flex-center">
                        <div className="menu-title">{item.title}
                        {
                          item.disabled ?<span className="comingsoon"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>:''
                        }
                        </div>
                        {/* <div className="menu-title-sub">{item.desc}</div> */}
                      </div>
                      <div className="arrow">
                        <img  src={arrow} alt="" />
                      </div>
                    </a>
                  </Menu.Item>)
                })
              }
            </Menu>
          }>
            <a className="" onClick={e => e.preventDefault()}>
              Airdrop <img className="icon-arrow" src={iconArrow} alt="" />
            </a>
          </Dropdown>
        </div>
{/* 
        <div className="nav-item">
        <Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
            <Menu>
              {
                presaleList.map((item, index) => {
                  return (<Menu.Item key={index} disabled={item.disabled}>
                    <a href={item.link} target="_blank">
                      <img src={item.icon} alt={item.title} style={{width: '32px'}}/>
                      <div className="menu-wrapper m-r-50 flex flex-center">
                        <div className="menu-title">{item.title}
                        {
                          item.disabled ?<span className="comingsoon"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>:''
                        }
                        </div>
                        <div className="menu-title-sub">{item.desc}</div>
                      </div>
                      <div className="arrow">
                        <img  src={arrow} alt="" />
                      </div>
                    </a>
                  </Menu.Item>)
                })
              }
            </Menu>
          }>
            <a className="" onClick={e => e.preventDefault()}>
              Presale <img className="icon-arrow" src={iconArrow} alt="" />
            </a>
          </Dropdown>
        </div> */}
        <div className="nav-item">
        <Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
            <Menu>
              {
                whitelistList.map((item, index) => {
                  return (<Menu.Item key={index} disabled={item.disabled}>
                    <a href={item.link} target="_blank">
                      <img src={item.icon} alt={item.title} style={{width: '32px'}}/>
                      <div className="menu-wrapper m-r-50 flex flex-center">
                        <div className="menu-title">{item.title}
                        {
                          item.disabled ?<span className="comingsoon"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>:''
                        }
                        </div>
                        {/* <div className="menu-title-sub">{item.desc}</div> */}
                      </div>
                      <div className="arrow">
                        <img  src={arrow} alt="" />
                      </div>
                    </a>
                  </Menu.Item>)
                })
              }
            </Menu>
          }>
            <a className="" onClick={e => e.preventDefault()}>
              Allowlist <img className="icon-arrow" src={iconArrow} alt="" />
            </a>
          </Dropdown>
        </div>
        {/* <div className="nav-item">
        <Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
            <Menu>
              {
                farmlinks.map((item, index) => {
                  return (<Menu.Item key={index} disabled={item.disabled}>
                    <NavLink to={item.link}>
                      <img src={item.icon}  alt={item.title} />
                      <div className="menu-wrapper m-r-50">
                        <div className="menu-title">{item.title}
                        {
                          item.disabled ?
                        <span className="comingsoon"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>:''
                        }
                        </div>
                        {/* <div className="menu-title-sub">{item.desc}</div> */}
                      {/* </div>
                      <div className="arrow">
                        <img src={arrow} alt="" />
                      </div>
                    </NavLink>
                  </Menu.Item>)
                })
              }
            </Menu>
          }>
            <a className="" onClick={e => e.preventDefault()}>
              Farm <img className="icon-arrow" src={iconArrow} alt="" />
            </a>
          </Dropdown>
        </div>  */}
        {/* <div className="nav-item">
          <Tooltip placement="bottom" title="Coming soon">
            <a>Bridge</a>
          </Tooltip>
        </div>
        <div className="nav-item">
          <Tooltip placement="bottom" title="Coming soon">
            <a>DAO</a>
          </Tooltip>
        </div>
        <div className="nav-item">
        <Tooltip placement="bottom" title="Coming soon">
            <a>Claim</a>
          </Tooltip>
        </div> */}
        <div className="nav-item">
          <Dropdown placement="bottom" overlayClassName="header-nav-dropdown" atip overlay={
            <Menu>
              {
                morelinks.map((item, index) => {
                  return (<Menu.Item key={index} disabled={item.disabled}>
                    {
                      item.isoutlink ? (
                        <a href={item.link} target="_blank">
                          {
                            item.icon ?
                            <img src={item.icon}  alt={item.title} />
                            : ''
                          }
                          <div className="menu-wrapper m-r-50">
                            <div className="menu-title">{item.title}
                            {
                                item.disabled ?
                                <span className="comingsoon"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>
                                :''
                              }
                            </div>
                            {/* <div className="menu-title-sub">{item.desc}</div> */}
                          </div>
                          <div className="arrow">
                            <img src={arrow} alt="" />
                          </div>
                        </a>
                      ):(
                        <NavLink to={item.link}>
                          {
                            item.icon ?
                            <img src={item.icon}  alt={item.title} />
                            : ''
                          }
                          <div className="menu-wrapper m-r-50">
                            <div className="menu-title">{item.title}
                            {
                                item.disabled ?
                                <span className="comingsoon"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>
                                :''
                              }
                            </div>
                            {/* <div className="menu-title-sub">{item.desc}</div> */}
                          </div>
                          <div className="arrow">
                            <img src={arrow} alt="" />
                          </div>
                        </NavLink>
                      )
                    }
                  </Menu.Item>)
                })
              }
              <Menu.SubMenu title="Exchagne">
              {
                exchangeLinks.map((item, index) => {
                  return (<Menu.Item key={index} disabled={item.disabled}>
                    <a href={item.link} target="_blank">
                      <img src={item.icon} alt={item.title} style={{width: '32px'}}/>
                      <div className="menu-wrapper m-r-50 flex flex-center">
                        <div className="menu-title">{item.title}
                        {
                          item.disabled ?<span className="comingsoon"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>:''
                        }
                        </div>
                        {/* <div className="menu-title-sub">{item.desc}</div> */}
                      </div>
                      <div className="arrow">
                        <img  src={arrow} alt="" />
                      </div>
                    </a>
                  </Menu.Item>)
                })
              }
              </Menu.SubMenu>

              <Menu.SubMenu title="Farm">
              {
                farmlinks.map((item, index) => {
                  return (<Menu.Item key={index} disabled={item.disabled}>
                    <NavLink to={item.link}>
                      <img src={item.icon}  alt={item.title} />
                      <div className="menu-wrapper m-r-50">
                        <div className="menu-title">{item.title}
                        {
                          item.disabled ?
                        <span className="comingsoon"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>:''
                        }
                        </div>
                        <div className="menu-title-sub">{item.desc}</div>
                      </div>
                      <div className="arrow">
                        <img src={arrow} alt="" />
                      </div>
                    </NavLink>
                  </Menu.Item>)
                })
              }
              </Menu.SubMenu>
               
            </Menu>
          }>
            <a className="" onClick={e => e.preventDefault()}>
              More <img className="icon-arrow" src={iconArrow} alt="" />
            </a>
          </Dropdown>
        </div>
        {/* <div className="nav-item">
          <a href="https://airdrop.kepler.homes/" target="_blank">Airdrop</a>
        </div> */}
        {/* <div className="nav-item">
          <a href="https://nft-mint.kepler.homes/nft-whitelist" target="_blank">NFT-Whitelist</a>
        </div>
        <div className="nav-item">
          <a href="https://nft-mint.kepler.homes" target="_blank">NFT-Mint <span className="comingsoon"><img src={require('../../assets/images/base/hot.svg').default} alt="" /></span></a>
        </div> */}
      </div>
      <div className="header-right">
        <div className="icon-list flex flex-center">
          {/* <Dropdown placement="bottom"  atip overlay={
            <Menu>
              <Menu.Item key="0">
              <NavLink to="/download">
                <div>
                  <div className="menu-title">Windows</div>
                  <div className="menu-title-sub">Coming soon</div>
                </div>
              </NavLink>
              </Menu.Item>
              <Menu.Item key="1">
              <NavLink to="/download">
                <div>
                  <div className="menu-title">MacOS</div>
                  <div className="menu-title-sub">Coming soon</div>
                </div>
              </NavLink>

              </Menu.Item>
            </Menu>
          }> */}
            {/* <WhiteType account={props.account} whiteType={whiteType} whiteimg={whiteimg} whiteimgmodal={whiteimgmodal}/> */}

           <Tooltip title="Coming soon">
           <div className="icon-item"><img src={iconDownload} alt="" width="30"/></div>
           </Tooltip>
          {/* </Dropdown> */}
          {/* <Login/> */}
        </div>
        <ConnectWallet />
      </div>
      <div className="login-mobile show-m">
         <Login notdispatch={true}/>
      </div>
      <div className="mobile-menu" onClick={foldfn}>
        <img src={iconMenu} alt="" />
      </div>
    </div>
    
    <div className='show-m'>
      <MobileMenu />
    </div>
    </>
  )
}

// export default ;
export default connect(
  (state, props) => {
    return {...state, ...props}
  }
)(
  Header
);

