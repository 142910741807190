export default {
    "KEPL": "0x000aa5384e9d90a6cd1d2d4ce3ec605570f7bbbf",
    "USDT": "0xc7198437980c041c805a1edcba50c1ce5db95118",
    "BUSD": "0x19860ccb0a68fd4213ab9d8266f7bbf05a8dde98",
    "USDC": "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
    "ETH": "0xf20d962a6c8f70c731bd838a3a388d7d48fa6e15",
    "WAVAX": "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7",
    "PreKEPL": "0x755629d75ae5703fd6a8fb5284d1d647d2b553b0",
    "ChainlinkOracle": "0xdd17d78c1d996d133367f738729a49277d5ef3d3",
    "KeplerSuit": "0xd7ebc0e785c75bef0c9a8da4c0bc373288720230",
    "MysteryBox": "0xebd468de87c001ad420b9a72e0076c921492f853"
}