export default [
  {
  "anonymous": false,
  "inputs": [
  {
  "indexed": false,
  "internalType": "uint256",
  "name": "itemId",
  "type": "uint256"
  },
  {
  "indexed": false,
  "internalType": "address",
  "name": "buyer",
  "type": "address"
  },
  {
  "indexed": false,
  "internalType": "uint256",
  "name": "price",
  "type": "uint256"
  },
  {
  "indexed": false,
  "internalType": "uint256",
  "name": "fee",
  "type": "uint256"
  }
  ],
  "name": "Buy",
  "type": "event"
  },
  {
  "anonymous": false,
  "inputs": [
  {
  "indexed": false,
  "internalType": "uint256",
  "name": "itemId",
  "type": "uint256"
  }
  ],
  "name": "Close",
  "type": "event"
  },
  {
  "anonymous": false,
  "inputs": [
  {
  "indexed": false,
  "internalType": "address",
  "name": "operator",
  "type": "address"
  },
  {
  "indexed": false,
  "internalType": "address",
  "name": "from",
  "type": "address"
  },
  {
  "indexed": false,
  "internalType": "uint256",
  "name": "tokenId",
  "type": "uint256"
  },
  {
  "indexed": false,
  "internalType": "bytes",
  "name": "data",
  "type": "bytes"
  }
  ],
  "name": "NFTReceived",
  "type": "event"
  },
  {
  "anonymous": false,
  "inputs": [
  {
  "indexed": false,
  "internalType": "uint256",
  "name": "id",
  "type": "uint256"
  },
  {
  "indexed": false,
  "internalType": "address",
  "name": "nft",
  "type": "address"
  },
  {
  "indexed": false,
  "internalType": "uint256",
  "name": "tokenId",
  "type": "uint256"
  },
  {
  "indexed": false,
  "internalType": "address",
  "name": "currency",
  "type": "address"
  },
  {
  "indexed": false,
  "internalType": "uint256",
  "name": "price",
  "type": "uint256"
  },
  {
  "indexed": false,
  "internalType": "uint256",
  "name": "fee",
  "type": "uint256"
  }
  ],
  "name": "Open",
  "type": "event"
  },
  {
  "inputs": [
  {
  "internalType": "uint256",
  "name": "itemId",
  "type": "uint256"
  }
  ],
  "name": "buy",
  "outputs": [],
  "stateMutability": "payable",
  "type": "function"
  },
  {
  "inputs": [
  {
  "internalType": "uint256",
  "name": "itemId",
  "type": "uint256"
  }
  ],
  "name": "close",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
  },
  {
  "inputs": [
  {
  "internalType": "uint256",
  "name": "id",
  "type": "uint256"
  },
  {
  "internalType": "address",
  "name": "nft",
  "type": "address"
  },
  {
  "internalType": "uint256",
  "name": "tokenId",
  "type": "uint256"
  },
  {
  "internalType": "address",
  "name": "currency",
  "type": "address"
  },
  {
  "internalType": "uint256",
  "name": "price",
  "type": "uint256"
  },
  {
  "internalType": "uint256",
  "name": "deadline",
  "type": "uint256"
  }
  ],
  "name": "encode",
  "outputs": [
  {
  "internalType": "bytes32",
  "name": "",
  "type": "bytes32"
  }
  ],
  "stateMutability": "pure",
  "type": "function"
  },
  {
  "inputs": [],
  "name": "feeRate",
  "outputs": [
  {
  "internalType": "uint256",
  "name": "",
  "type": "uint256"
  }
  ],
  "stateMutability": "view",
  "type": "function"
  },
  {
  "inputs": [
  {
  "internalType": "uint256",
  "name": "itemId",
  "type": "uint256"
  }
  ],
  "name": "getItem",
  "outputs": [
  {
  "components": [
  {
  "internalType": "uint256",
  "name": "id",
  "type": "uint256"
  },
  {
  "internalType": "address",
  "name": "nft",
  "type": "address"
  },
  {
  "internalType": "uint256",
  "name": "tokenId",
  "type": "uint256"
  },
  {
  "internalType": "address",
  "name": "currency",
  "type": "address"
  },
  {
  "internalType": "uint256",
  "name": "price",
  "type": "uint256"
  },
  {
  "internalType": "uint256",
  "name": "fee",
  "type": "uint256"
  },
  {
  "internalType": "uint8",
  "name": "status",
  "type": "uint8"
  },
  {
  "internalType": "address",
  "name": "seller",
  "type": "address"
  },
  {
  "internalType": "address",
  "name": "buyer",
  "type": "address"
  }
  ],
  "internalType": "struct INFTMarket.Item",
  "name": "",
  "type": "tuple"
  }
  ],
  "stateMutability": "view",
  "type": "function"
  },
  {
  "inputs": [
  {
  "internalType": "uint8",
  "name": "status",
  "type": "uint8"
  }
  ],
  "name": "getItems",
  "outputs": [
  {
  "components": [
  {
  "internalType": "uint256",
  "name": "id",
  "type": "uint256"
   },
  {
  "internalType": "address",
  "name": "nft",
  "type": "address"
  },
  {
  "internalType": "uint256",
  "name": "tokenId",
  "type": "uint256"
  },
  {
  "internalType": "address",
  "name": "currency",
  "type": "address"
  },
  {
  "internalType": "uint256",
  "name": "price",
  "type": "uint256"
  },
  {
  "internalType": "uint256",
  "name": "fee",
  "type": "uint256"
  },
  {
  "internalType": "uint8",
  "name": "status",
  "type": "uint8"
  },
  {
  "internalType": "address",
  "name": "seller",
  "type": "address"
  },
  {
  "internalType": "address",
  "name": "buyer",
  "type": "address"
  }
  ],
  "internalType": "struct INFTMarket.Item[]",
  "name": "items",
  "type": "tuple[]"
  }
  ],
  "stateMutability": "view",
  "type": "function"
  },
  {
  "inputs": [],
  "name": "getSupportedCurrencies",
  "outputs": [
  {
  "internalType": "address[]",
  "name": "currencies",
  "type": "address[]"
  }
  ],
  "stateMutability": "view",
  "type": "function"
  },
  {
  "inputs": [],
  "name": "getSupportedNFTs",
  "outputs": [
  {
  "internalType": "address[]",
  "name": "nfts",
  "type": "address[]"
  }
  ],
  "stateMutability": "view",
  "type": "function"
  },
  {
  "inputs": [
  {
  "internalType": "uint256",
  "name": "id",
  "type": "uint256"
  },
  {
  "internalType": "address",
  "name": "nft",
  "type": "address"
  },
  {
  "internalType": "uint256",
  "name": "tokenId",
  "type": "uint256"
  },
  {
  "internalType": "address",
  "name": "currency",
  "type": "address"
  },
  {
  "internalType": "uint256",
  "name": "price",
  "type": "uint256"
  },
  {
  "internalType": "uint256",
  "name": "deadline",
  "type": "uint256"
  },
  {
  "internalType": "bytes",
  "name": "signature",
  "type": "bytes"
  }
  ],
  "name": "open",
  "outputs": [],
  "stateMutability": "nonpayable",
  "type": "function"
  }
  ]