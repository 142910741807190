import Airnav from "../components/layouts/Airnav"
import './Airdropclaim.scss'
import { Button } from "antd"
import {claim, queryVariables, isClaimed} from '../contract/methods/airdrop'
import { useEffect, useState} from "react"
import { get } from "../http"
import store, { setToken, setUserInfo } from '../store'
import {connect, useSelector} from 'react-redux'
import { findNameByAddress, fromUnit, showConnectWallet, toUnit, toWei } from "../lib/util"
import notification from "../components/notification"
import { tokenList } from "../lib/airdrop"
const Claim = (props) => {
    
    let [proof, setProof] = useState([])
    let [rewardToken, setRewardToken] = useState('')
    let [rewardAmount, setRewardAmount] = useState(0)
    let [lastNum, setLastNum] = useState(0)
    let [claimed, setClaimed] = useState(false)
    let [showRewardAmount, setShowRewardAmount] = useState(0)
    let [statusText, setStatusText] = useState('claim')
    let [disabled, setDisabled] = useState(true)
    let [refresh, setRefresh] = useState(0)
    let [loading, setLoading] = useState(false)
    
    const toClaim =()=> {
        setLoading(true)
        claim(proof, props.info.tokenName).then(res => {
            setRefresh(refresh+1)
            setLoading(false)
        }).catch(err =>{
            setLoading(false)
            notification.error({
                message: ('Claim Fail')
            });
        })
    }
    const getProof = (claimer) => {
        get('/api/airdrop', {
            tokenType: props.info.type,
            claimer
        }).then(res => {
            setProof(res.data.proof)
        })
    } 
    useEffect(async ()=>{
        if(props.account && props.info.type) {
            getProof(props.account)
            let config = await queryVariables(props.info.tokenName)
            setClaimed(await isClaimed(props.info.tokenName, props.account))
            console.log(config)
            setRewardToken(findNameByAddress(config.rewardToken))
            setRewardAmount(fromUnit(config.rewardAmount))
            setLastNum(fromUnit(config.totalRewardAmount) - fromUnit(config.totalClaimedRewardAmount))
        }
        
    },[props.account, refresh])


    useEffect(() => {
        if(claimed) {
           setStatusText('Claimed')
           setDisabled(true)
           return
        }
        if(lastNum <= 0) {
            setStatusText('Claim end')
            setDisabled(true)
         } else if(proof.length == 0) {
            setStatusText('Claim')
            setDisabled(true)
         } else {
            setStatusText('Claim')
            setDisabled(false)
         }
    }, [claimed, lastNum, proof])

    

    useEffect(() => {
        if(proof.length == 0) {
            setShowRewardAmount(0)
        } else {
            setShowRewardAmount(rewardAmount)
        }
    }, [rewardAmount, proof])

    return (
        <div className={"claim-item flex flex-between flex-center "+(props.info.active && 'active')}>
        <span className="flex-1 flex flex-center">
          <img src={props.info.icon} alt="" className="token-logo"/>
          <span className="fwb fz-16 m-l-14">{props.info.name}</span>
        </span>
        <span className="fwb fz-16 m-r-84">{showRewardAmount} {rewardToken}</span>
        {
            props.account ?<Button className="claim-btn pointer" loading={loading} disabled={disabled} onClick={toClaim}>{statusText}</Button>:
            <Button className="color my-button claim-btn"   onClick={showConnectWallet}>Connect Wallet</Button>
        }
        
      </div>
    )
}
export default connect(
    (state, props) => {
      return {...state, ...props}
    }
  )( (props)=> {
    

    return (
        <div className="airdrop-claim w100 m-t-0 flex flex-column ">
            <div className="air-header">
               <Airnav/>
            </div>
            <div className="airdrop-banner-claim p-t-0 w100 flex flex-column flex-center flex-1">
                <img src={require('../assets/images/airdrop/claimbanner.png')} alt="" />
                <div className="claim-area">
                    <div className="claim-title flex flex-between fz-14 m-b-12">
                        <span className="flex-1 p-l-66">Your Airdrop Reward</span>
                        <span className="m-r-84">Quantity</span>
                        <span className="state-title">State</span>
                    </div>
                     {
                        tokenList.map(item => {
                            return <Claim info={item} {...props} key={item.type}/>
                        })
                     }
                    {/* <div className="ta m-t-20">
                        <span className="fz-16 fw500">Total  <span className="fwb fz-24">  400  pre-KEPL </span></span>
                    </div> */}
                </div>

            </div>
            
                
            <div className="footer flex flex-center flex-middle">
                        <img src={require('../assets/images/airdrop/mini-logo.png')} alt="" className="mini-logo"/>
                        <span className="fz-14 m-l-11">Kepler © 2022, All rights reserved</span>
            </div>

        </div>
    )
})