export const tokenList = [
    {
        tokenName: 'BEND',
        className: 'bend',
        name: 'BendDAO',
        type: 3,
        active: true,
        icon: require('../assets/images/token/BEND.png')
    },
    {
        tokenName: 'APE',
        name: 'ApeCoin',
        type: 1,
        active: true,
        icon: require('../assets/images/token/APE.png')
    },
    {
        tokenName: 'LOOKS',
        name: 'LOOKSRARE',
        className: 'looksrare',
        type: 2,
        icon: require('../assets/images/token/LOOKSRARE.png')
    }
]