export default {
  "KEPL": {
    "publicKey": "22sEECs8L66UH7os4B4SAb6qhCdSBmHmE3vaX1NsVWJu",
    "programId": "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA",
    "payer": "3ADRkMndYdbtF4jNXKAGjEE3oYf3d6peSkPppu1gZC8M",
    "deployerAccount": "2aFgqGuy4c5DYrmdpoav11SzvHvgZw4L8aK7kVW6apwT"
  },
  "KEME": {
    "publicKey": "6uPU5ch2xjJQ7Xps6zbGXnYTruwtMwdpGXihkcZWPVb5",
    "programId": "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA",
    "payer": "3ADRkMndYdbtF4jNXKAGjEE3oYf3d6peSkPppu1gZC8M",
    "deployerAccount": "55Nod7SWi9C4c8k3XKLGeddX1feyFSCKTmPVehZEhvaw"
  },
  "pools": {
    "programId": "J4PPX9AeiytJdCBhErRb8uGE5vWhKD7Fzf6GWvhbVvyY",
    "deployer": "3ADRkMndYdbtF4jNXKAGjEE3oYf3d6peSkPppu1gZC8M",
    "deployTime": "2022-04-27T22:02:52+08:00",
    "upgradeTime": null,
    "upgrade": false,
    "rewardVault": "Ar5SUwFQYuWJZn77z4zueJVFGoqva8RpgYytu8trqkFr",
    "globalAccount": "D6WJuaSXD1bg4mYhMUL6fomcFgm3jCfqATxwfyh3VrvS",
    "rewardToken": "22sEECs8L66UH7os4B4SAb6qhCdSBmHmE3vaX1NsVWJu",
    "deposits": {
      "KEPL": {
        "globalAccount": "D6WJuaSXD1bg4mYhMUL6fomcFgm3jCfqATxwfyh3VrvS",
        "poolVault": "wqt766nV5XABpBjKw12RxTxw6NNJo4nGG3SKU8xneoZ",
        "deployer": "3ADRkMndYdbtF4jNXKAGjEE3oYf3d6peSkPppu1gZC8M",
        "depositToken": "22sEECs8L66UH7os4B4SAb6qhCdSBmHmE3vaX1NsVWJu"
      },
      "KEME": {
        "globalAccount": "D6WJuaSXD1bg4mYhMUL6fomcFgm3jCfqATxwfyh3VrvS",
        "poolVault": "4ehYTDr4mzbzMWThiCFYfsKc4i1FkEEzdkKe8MQu2tEH",
        "deployer": "3ADRkMndYdbtF4jNXKAGjEE3oYf3d6peSkPppu1gZC8M",
        "depositToken": "6uPU5ch2xjJQ7Xps6zbGXnYTruwtMwdpGXihkcZWPVb5"
      }
    }
  },
  "bridge": {
    "programId": "AaYvypao2X3E44EzkuytSR1MbD3cF2UTRn4nNiYoZQLE",
    "deployer": "3ADRkMndYdbtF4jNXKAGjEE3oYf3d6peSkPppu1gZC8M",
    "deployTime": "2022-04-26T16:45:52+08:00",
    "upgradeTime": null,
    "upgrade": false,
    "supportedTokens": [
      "22sEECs8L66UH7os4B4SAb6qhCdSBmHmE3vaX1NsVWJu"
    ]
  }
}