
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Tooltip, Button, Tabs, Skeleton, Table} from 'antd';
import { useEffect } from 'react'
import { useState } from 'react';
import Modal from '../Base/Modal';
import { addPoint, findAddressByName, findNameByAddress, formatTime, formatTimeShort, fromUnit, numFormat, showConnectWallet, toFixed, toUnit, toWei, ZERO_ADDRESS } from '../../lib/util'
import { content } from '../layouts/Menu';
import {connect} from 'react-redux'
import './Profile.scss'
import { allowance, approve, balanceOf } from '../../contract/methods';
import { get, post } from '../../http';
import { depositToken } from '../../contract/methods/asset';
import { MaxUint256 } from '@ethersproject/constants';
import notification from '../notification';
const { TabPane } = Tabs;
const statusText = ['Processing', 'Confirming', 'To be claimed', 'Completed', 'Fail']
const Withdraw = (props) => {
    let [selectToken, setSelectToken] = useState('KEPL')
    let [inputNum, setNum] = useState(0)
    let [refresh, setRefresh] = useState(0)
    let [loading, setLoading] = useState(false)
    let [claim_list, setClaimList] = useState([])
    let [bal_api, setBal] = useState({
        KEME:0,
        KEPL:0
    })
    let [allow, setAllowance] = useState({
        KEME:0,
        KEPL:0
    })
    const columns = [
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount, record) => (
                <span className='flex flex-center'>
                     {
                            amount
                     }
                     {
                       findNameByAddress(record.contract) 
                     }
                </span>
            ),
        },
        {
            title: 'Withdraw Time',
            dataIndex: 'updateTime',
            align: 'center',
            key: 'updateTime',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            align: 'center',
            key: 'status',
            render: (status, record) => (
                <span className='flex flex-center'>
                     {
                            statusText[status]
                     }
                </span>
            ),

        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            align: 'right',
            render: (num, record) => (
                <Button className='color my-button cf p-l-5 p-r-5' disabled={record.status != 2}>Claim</Button>
            ),
        }
    ];


    let toWithdraw = async () => {
        setLoading(true)
        post('/api/v1/game/withdraw_token', {
            contractAddress: findAddressByName(selectToken),
            amount: inputNum
        }).then(res => {
            notification.success({
                message: 'Withdraw Supply Success',
                description: 'Please Check the Withdraw Claim List to Claim'
            })
            setRefresh(refresh+1)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }
    let onChange = (num)=>{
        setNum(num)
    }
    useEffect(()=>{
        (async ()=>{
            if(!props.account) {
                return
            }
            let bal_kepl = 0
            let bal_keme = await get('/api/v1/game/query_token')
            let all_kepl = await allowance(findAddressByName('KEPL'), findAddressByName('AssetManager')).call()
            let all_keme = await allowance(findAddressByName('KEME'), findAddressByName('AssetManager')).call()
            let claim_list = await get('/api/v1/game/token_list')
            console.log(claim_list)
            console.log(props)
            setClaimList(claim_list.data.claim)
            setBal({
                KEME: bal_keme.data.subTokenBalance,
                KEPL: bal_kepl
            })
            setAllowance({
                KEME: all_keme,
                KEPL: all_kepl
            })
        })()
    }, [props.account, props.showWithdraw, refresh])
    return (

        <Tabs className='my-tab white-tab'>
        <TabPane tab={('Supply withdraw')} key={1}>

        <div className='w100'>
            <div className="c006">Token</div>
            <div className='token-box m-t-6'>
                <span onClick={()=>setSelectToken('KEPL')} className={'token-item pointer '+ (selectToken =='KEPL'?'active':'')}>KEPL</span>
                <span onClick={()=>setSelectToken('KEME')}  className={'token-item pointer '+ (selectToken =='KEME'?'active':'')}>KEME</span>
            </div>
            {/* <div className="c006 m-t-16">Game balance ({selectToken})</div>
            <div className="m-t-6 fwb fz-16">
                {bal_api[selectToken]}
            </div> */}
            <div>
                <ChooseToken onChange={onChange} maxNum={bal_api[selectToken]} showTransmit={props.showTransmit} {...props} selectToken={selectToken} />
            </div>
            <div className="c006 m-t-16 ta flex flex-center flex-middle">
                <span className='flex flex-center'>
                 Fee
                <img className='m-l-3' src={require('../../assets/images/user/question.svg').default} alt="" />
                :
                </span>
                <span className="m-l-10 fwb fz-16">
                    0.5KEPL
                </span>
            </div>
            {
                // allow[selectToken] > 0 ?
                <Button onClick={toWithdraw} disabled={inputNum<=0} loading={loading} className="w100 color cf my-button transmit-btn m-t-20">
                    Withdraw
                </Button>

                // <Button onClick={toApprove} loading={loading} className="w100 color cf my-button transmit-btn m-t-20">
                //     Approve {selectToken}
                // </Button>
            }
            
        </div>
        </TabPane>
        <TabPane tab={('Withdraw Claim')} key={2}>
            <div className="w100 claim-wrap">
                <Table style={{maxHeight: 400, overflow: 'auto'}} columns={columns} dataSource={claim_list} pagination={false} className="my-table white-table" />
            </div>
        </TabPane>
        </Tabs>
    )
}
const Transmit = (props) => {
    let [selectToken, setSelectToken] = useState('KEPL')
    let [inputNum, setNum] = useState(0)
    let [loading, setLoading] = useState(false)
    let [bal_api, setBal] = useState({
        KEME:0,
        KEPL:0
    })
    let [allow, setAllowance] = useState({
        KEME:0,
        KEPL:0
    })

    let [bal, setBalance] = useState({
        KEME:0,
        KEPL:0
    })


    let toTransmit = async () => {
        setLoading(true)
        depositToken(findAddressByName(selectToken), toWei(inputNum)).then(res => {
            props.onClose()
        }).finally(err=>{
            setLoading(false)
        })
    }
    let toApprove = () => {
        setLoading(true)
        approve(findAddressByName(selectToken), findAddressByName('AssetManager')).then(res => {
            let all = allow
            all[selectToken] = MaxUint256
            setAllowance(all)
        }).finally(res => {
            setLoading(false)
        })
    }
    let onChange = (num)=>{
        setNum(num)
    }
    useEffect(()=>{
        (async ()=>{
            if(!props.account) {
                return
            }
            let bal_kepl = 0
            let bal_keme = await get('/api/v1/game/query_token')
            let all_kepl = await allowance(findAddressByName('KEPL'), findAddressByName('AssetManager')).call()
            let all_keme = await allowance(findAddressByName('KEME'), findAddressByName('AssetManager')).call()
            let balkepl = await balanceOf(findAddressByName('KEPL'), props.account)
            let balkeme = await balanceOf(findAddressByName('KEME'), props.account)
            
            setBalance({
                KEME: toFixed(fromUnit(balkeme), 4),
                KEPL: toFixed(fromUnit(balkepl), 4)
            })
            console.log(props)
            setBal({
                KEME: bal_keme.data.subTokenBalance,
                KEPL: bal_kepl
            })
            setAllowance({
                KEME: all_keme,
                KEPL: all_kepl
            })
        })()
    }, [props.account, props.showTransmit])
    return (
        <div className='w100'>
            <div className="c006">Token</div>
            <div className='token-box m-t-6'>
                <span onClick={()=>setSelectToken('KEPL')} className={'token-item pointer '+ (selectToken =='KEPL'?'active':'')}>KEPL</span>
                <span onClick={()=>setSelectToken('KEME')}  className={'token-item pointer '+ (selectToken =='KEME'?'active':'')}>KEME</span>
            </div>
            <div className="c006 m-t-16">Game balance ({selectToken})</div>
            <div className="m-t-6 fwb fz-16">
                {bal_api[selectToken]}
            </div>
            <div>
                <ChooseToken onChange={onChange} maxNum={bal[selectToken]} showTransmit={props.showTransmit} {...props} selectToken={selectToken} />
            </div>
            <div className="c006 m-t-16 ta flex flex-center flex-middle">
                <span className='flex flex-center'>
                 Fee
                <img className='m-l-3' src={require('../../assets/images/user/question.svg').default} alt="" />
                :
                </span>
                <span className="m-l-10 fwb fz-16">
                    0.5KEPL
                </span>
            </div>
            {
                allow[selectToken] > 0 ?
                <Button onClick={toTransmit} loading={loading} className="w100 color cf my-button transmit-btn m-t-20">
                    Transmit
                </Button>:
                <Button onClick={toApprove} loading={loading} className="w100 color cf my-button transmit-btn m-t-20">
                    Approve {selectToken}
                </Button>
            }
            
        </div>
    )
}
const ChooseToken = (props) => {
    let [percent , setPercent] = useState(0)
    let [inputNum, setInputNum] = useState('')
    let [balance, setBalance] = useState(0)
    const handleSetAmount = (value) => {
        if (value === '') {
            setInputNum(value)
          return
        }
        if (!(/^\d+((\.\d+)?|(\.))$/.test(value))) {
          return
        }
        if (value.split('.').length > 1 && value.split('.')[1].length > 3) {
          return
        }
        if (value < 0) {
          return
        }
        setInputNum(value)
      }

    useEffect(()=>{
        props.onChange(inputNum)
    }, [inputNum])

    useEffect(() => {
        setInputNum('')
        setPercent(0)
    }, [props.refresh])

    useEffect(()=>{
        setBalance(props.maxNum)
    }, [props.maxNum])
    useEffect(()=>{
        if(props.account) {
            setInputNum(toFixed(balance*percent/100, 3)||'')
        } else {
            setInputNum('')
        }
    }, [percent, balance])
    return  (
        <div className="choose w100 m-t-16">
            <div className='choose-token flex flex-column p-l-24 p-r-16'>
                <div className="left-item flex flex-center">
                   <span className='c006 fz-14 m-b-9 flex-1 m-t-11'>Available:{props.account ? (balance ? toFixed(Number(balance), 3):0):'--'}</span>
                   <div className="percent flex flex-between m-t-11">
                        <div className={"pointer fz-14 percent-item ta c006 "+(percent == 25 ? 'active':'')} onClick={()=> {setPercent(25)}}>
                            25%
                        </div>
                        <div className={"pointer fz-14 percent-item ta c006 "+(percent == 50 ? 'active':'')}  onClick={()=> {setPercent(50)}}>
                            50%
                        </div>
                        <div className={"pointer fz-14 percent-item ta c006 "+(percent == 75 ? 'active':'')}  onClick={()=> {setPercent(75)}}>
                            75%
                        </div>
                        <div className={"pointer fz-14 percent-item ta c006 "+(percent == 100 ? 'active':'')}  onClick={()=> {setPercent(100)}}>
                            MAX
                        </div>
                    </div>
                   
                </div>
               
                <div className="right-item flex flex-center flex-between">
                    <input type="text" className='number-input fz-24 fwb c006' placeholder='0' value={inputNum} onChange={(e) => handleSetAmount(e.target.value)}/>
                    
                </div>
            </div>
            

        </div>
    )
}
let Profile =  (props) => {
    let [visible, setVisible] = useState(true)
    let [showTransmit, setShowTransmit] = useState(false)
    let [showWithdraw, setShowWithdraw] = useState(false)
    let [balkepl, setBalancekepl] = useState(0)
    let [balkeme, setBalancekeme] = useState(0)
    let [balkeplapi, setBalancekeplapi] = useState(0)
    let [balkemeapi, setBalancekemeapi] = useState(0)
    let [loading, setLoading] = useState(false)
    useEffect(() => {
        (async ()=>{
            if(props.account) {
                setLoading(true)
                let bal_kp = await balanceOf(findAddressByName('KEPL'), props.account)
                let bal_km = await balanceOf(findAddressByName('KEME'), props.account)
                let bal_api = await get('/api/v1/game/query_token')
                console.log(bal_api)
                console.log(bal_kp, bal_km)
                setBalancekepl(bal_kp)
                setBalancekeme(bal_km)
                setBalancekemeapi(bal_api.data.subTokenBalance)
                console.log(balkepl)
                console.log(balkeme)
                setLoading(false)
            }
        })()
    }, [props.account])

    return (
        <div className="profile">
            <div className="cf info-title show-p">My Assets</div>
            {/* total */}
                <div className="cf content-font money-box flex flex-center">
                    Total
                        <span className='eye m-l-5' onClick={()=>setVisible(!visible)}>
                        {visible ?<EyeOutlined/> : <EyeInvisibleOutlined/>}
                        </span>
                </div>
                <div className="">
                    <span className="cf money fw500 ">{
                        visible ? loading ? <Skeleton.Button size="small" />:toFixed(Number(balkeplapi)+Number(fromUnit(balkepl)), 4):'******'
                    }
                    </span>
                    <span className="cf content-font m-l-5">KEPL</span>
                    
                    <span className="cf money fw500 m-l-30">{
                        visible ?loading ? <Skeleton.Button size="small" />:toFixed(Number(balkemeapi)+Number(fromUnit(balkeme)),4):'******'
                    }
                    </span>
                    <span className="cf content-font m-l-5">KEME</span>
                    
                    <span className="cf money fw500 m-l-30">{
                        visible ?'5':'**'
                    }
                    </span>
                    <span className="cf content-font m-l-5">NFT</span>
                </div>
            {/* total */}
            <Tabs className='my-tab'>
                <TabPane tab={('Wallet assets')} key={1}>
                        <div className="flex flex-between m-t-12">
                        <div className="asset-box cf flex-1">
                            <div className="flex w100 flex-between">
                                <div className="flex flex-center token-name">
                                    <img className='logo' src={require('../../assets/images/token/KEPL.png')} alt="" />
                                    <span className='m-l-10'>KEPL</span>

                                </div>
                                <div className="flex flex-column flex-end">
                                    <span>{loading ? <Skeleton.Button size="small" />:toFixed(fromUnit(balkepl), 4)}</span>
                                    <span className='c06 fz-14'>≈$--</span>
                                </div>
                            </div>
                            
                            <Tooltip title={content} className="my-pop" placement="top">
                            <div className="color cf ta p-t-10 p-b-10 m-t-10 pointer">Buy KEPL</div>
                            </Tooltip>
                        </div><div className="asset-box cf flex-1 m-l-20">
                            <div className="flex w100 flex-between">
                                <div className="flex flex-center token-name">
                                    <img className='logo' src={require('../../assets/images/token/KEPL.png')} alt="" />
                                    <span className='m-l-10'>KEME</span>

                                </div>
                                <div className="flex flex-column flex-end">
                                    <span>{loading ? <Skeleton.Button size="small" />:toFixed(fromUnit(balkeme), 4)}</span>
                                    <span className='c06 fz-14'>≈$--</span>
                                </div>
                            </div>
                            <Tooltip title={content} className="my-pop" placement="top">
                            <div className="color cf ta p-t-10 p-b-10 m-t-10 pointer">Buy KEME</div>
                            </Tooltip>

                        </div>
                    </div>
                </TabPane>
                <TabPane tab={('Game assets')} key={2}>
                <div className="flex flex-between m-t-12 asset-box ">
                    <div className="cf flex-1">
                        <div className="flex w100 flex-between">
                            <div className="flex flex-center token-name">
                                <img className='logo' src={require('../../assets/images/token/KEPL.png')} alt="" />
                                <span className='m-l-10'>KEPL</span>

                            </div>
                            <div className="flex flex-column flex-end">
                                <span>{balkeplapi}</span>
                                <span className='c06 fz-14'>≈$--</span>
                            </div>
                        </div>
                        <div className="color cf ta p-t-10 p-b-10 m-t-10 pointer" onClick={()=>setShowTransmit(true)}>Transmit</div>
                    </div><div className="cf flex-1 m-l-20">
                        <div className="flex w100 flex-between">
                            <div className="flex flex-center token-name">
                                <img className='logo' src={require('../../assets/images/token/KEPL.png')} alt="" />
                                <span className='m-l-10'>KEME</span>

                            </div>
                            <div className="flex flex-column flex-end">
                                <span>{balkemeapi}</span>
                                <span className='c06 fz-14'>≈$--</span>
                            </div>
                        </div>
                        <div className="color cf ta p-t-10 p-b-10 m-t-10 pointer" onClick={()=>setShowWithdraw(true)}>Withdraw</div>
                    </div>
                </div>
                </TabPane>
            </Tabs>
            
            

            <Modal isVisible={showTransmit} margin={15} onClose={()=>setShowTransmit(false)} title="Transmit token to  Game">
                   <Transmit {...props} onClose={()=>setShowTransmit(false)} showTransmit={showTransmit}/>
            </Modal>
            <Modal isVisible={showWithdraw} margin={15} onClose={()=>setShowWithdraw(false)} title="Withdraw  token to Wallet">
                   <Withdraw {...props} onClose={()=>setShowWithdraw(false)} showWithdraw={showWithdraw}/>
            </Modal>


            
        </div>
    )
}

export default connect(
    (state, props) => {
      return {...state, ...props}
    }
  )(
    Profile
  );