import React, { useState, useEffect, useRef, useCallback} from 'react';
import {Tooltip} from 'antd'
import iconMenu from '../../assets/images/home/icon-menu.svg'
import { Menu, Dropdown } from 'antd';
import iconArrow from '../../assets/images/home/icon-arrow.svg'
// import logo from '../../assets/images/home/logo.svg'
import iconLanguage from '../../assets/images/home/icon-language.svg'
import iconDownload from '../../assets/images/home/icon-download.svg'
import logo from '../../assets/images/nav/logo.svg'
import menu from '../../assets/images/nav/menu.svg'
import classnames from 'classnames'
import './Mintnav.scss'
import { useTranslation} from 'react-i18next'
import ConnectWallet from '../ConnectWallet';
import Login from '../Login';
import Menumint from './Menumint'
import {connect, useSelector} from 'react-redux'
import notification from '../notification'

import Bus from '../../lib/eventBus'
import { NavLink } from 'react-router-dom';

let communityList = [
  {
    name: 'twitter',
    icon: require('../../assets/images/airdrop/twitter.svg').default,
    activeIcon: require('../../assets/images/airdrop/twitter_active.svg').default,
    link: 'https://twitter.com/KeplerHomes'
  },
  {
    name: 'telegram',
    icon: require('../../assets/images/airdrop/telegram.svg').default,
    activeIcon: require('../../assets/images/airdrop/telegram_active.svg').default,
    link: 'https://twitter.com/KeplerHomes'
  },
  {
    name: 'discord',
    icon: require('../../assets/images/airdrop/discord.svg').default,
    activeIcon: require('../../assets/images/airdrop/discord_active.svg').default,
    link: 'https://discord.gg/tFCMng5Mhd'
  },
  {
    name: 'medium',
    icon: require('../../assets/images/airdrop/medium.svg').default,
    activeIcon: require('../../assets/images/airdrop/medium_active.svg').default,
    link: 'https://medium.com/@KeplerHomes'
  }

]

function Header(props) {
  let { t ,i18n} = useTranslation()
  let language = i18n.language.split('-')[0]
  let [hasBg, setBg] = useState(false)
  const [isConnectWalletVisible, setIsConnectWalletVisible] = useState(false)
  
  let [isFold, setFold] = useState(false)


  const handleConnectWalletOk = () => {
    setIsConnectWalletVisible(false)
  }

  const handleConnectCancel = () => {
    setIsConnectWalletVisible(false)
  }

  const handleConnectWallet = () => {
    setIsConnectWalletVisible(true)
  }
  const copyAddress = useCallback(async () => {
    if(!props.account) {
      notification.error({
          message: t('Please connect your wallet first')
        });
        return
     }
    await navigator.clipboard.writeText('https://'+window.location.host+'/nft-mint?address='+props.account);
    notification.success({
      message: t('Invitation link has been generated, please send it to your friends!'),
    });
  }, [props.account]);
  useEffect(()=> {
    window.addEventListener('scroll', function() {
      if(document.documentElement.scrollTop > 0) {
        setBg(true)
      } else {
        setBg(false)
      }
    })
  }, [])

  
  let foldfn = () => {
    Bus.emit('foldChange', !isFold);
}

useEffect(() => {
    Bus.addListener('foldChange', (isfold) => {
        setFold(isfold)
    });
}, [])

  

  return (
    <div>
    <div className={classnames(["mint-header flex flex-between", {"has-bg": hasBg}])}>
     <div className="logo-wrap flex flex-center flex-middle flex-between logo-wrap-fold" >
         {/* <a href="/" className='logo'> */}
           <img src={logo} alt="" className='logo'/>
            <a className="nav-item cf  fz-18 m-l-40 islink" target="_blank" href="https://kepler.homes">
                    Home
              </a>
             <a className="nav-item cf  fz-18 m-l-40 islink" target="_blank" href="javascript:;">
                  Mint
                  <span className="comingsoon m-t-3"><img src={require('../../assets/images/base/soon.svg').default} alt="" /></span>
            </a>
             
            <a className="nav-item cf  fz-18 m-l-40 islink" target="_blank" href="https://beta.kepler.homes/nft-whitelist">
                  NFT-Whitelist
            </a>
            <div className="mobile-menu" onClick={foldfn}>
                <img src={iconMenu} alt="" />
            </div>

      </div>
      <div className="header-right">
        {
          communityList.map(item => {
            return (
              <a className="m-l-26 community_icon" href={item.link} target="_blank">
                <img src={item.icon} alt="" className='icon'/>
                <img src={item.activeIcon} alt="" className='active_icon'/>
              </a>
            )
          })
        }
        <ConnectWallet hideChain={['BSC','Avalanche', 'Polygon', 'Solana']} isVisible={isConnectWalletVisible} handleOk={handleConnectWalletOk} handleCancel={handleConnectCancel} />

      </div>
    </div>
      <div className="show-m">
        <Menumint></Menumint>
      </div>
    </div>
  )
}

export default connect(
  (state, props) => {
    return {...state, ...props}
  }
)(
  Header
);

