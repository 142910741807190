import './Share.scss'
import { post, get} from '../../http';
import { useState, useCallback } from 'react';
import store from '../../store';
import { ChainIdMap, showLogin } from '../../lib/util';
import notification from '../notification';
import { useTranslation} from 'react-i18next'
import { sign } from '../../contract/methods';
import {connect, useSelector} from 'react-redux'
import Viewer from 'react-viewer';
import { setToken, setUserInfo } from '../../store';
export default connect(
    (state, props) => {
      return {...state, ...props}
    }
  )(({info, showFav=true, ...props})=> {
    let{t} = useTranslation()
    let [nftInfo, setNftInfo] = useState(info)
    let [innerFavoirte, setInnerFavorite] = useState(0)
    console.log(info)
    
  const copyAddress = useCallback(async () => {
    
    await navigator.clipboard.writeText('https://'+window.location.host+'/NFT/'+info.orderId);
    notification.success({
      message: t('Copy finished'),
    });
  }, [info.orderId]);

  
  const toLogin = async() => {
    let signature = await sign('login')
    post('/api/account/connect', {
      chainId: ChainIdMap[localStorage.getItem('kepler_chain')||'ETH'],
      user: props.account,
      signature
    }).then(res => {
      props.dispatch(setToken(res.data.token))
      // get person info
      get('/api/v1/account').then(res => {
        store.dispatch(setUserInfo(res.data))
      })
    }).catch(err => {
      notification.error({
        message: t('Login Fail'),
        description: t('Something goes wrong')
    });
    })
  }
let toFav = useCallback((e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if(! store.getState().token) {
        toLogin()
        return
    }
    post('/api/v1/token/favorite', {
        chainId: ChainIdMap[store.getState().chain||'ETH'],
        tokenId: info.tokenId
    }).then(res => {
        console.log(res.data)
        setNftInfo({...nftInfo, isFavorite: res.data.favorite, favorite: res.data.count})
        setInnerFavorite(res.data.count)
    })
}, [info])
    return (
        <div className='cf flex flex-center'>
            <img src={require('../../assets/images/base/fenxiang.svg').default} alt="" onClick={copyAddress} className='pointer'/>
            
            { 
                showFav ?(nftInfo.isFavorite ? 
                <img  onClick={toFav} src={require('../../assets/images/base/xin_active.svg').default} alt="" className='m-l-16'/>
                :
                <img onClick={toFav} src={require('../../assets/images/base/xin.svg').default} alt="" className='m-l-16'/>
                ):''}
             {showFav ?<span className="cf fz-14 m-l-4">{innerFavoirte || info.favorite||'0'}</span>:''}
        </div>
    )
})