export default {
    "KEPL": "0xa325f0f4cb0f8c09ce315c88e9776d9935125c8b",
    "KEME": "0xda6f42cff9057b49b81cb8075016cf0bf02e1e1d",
    "USDT": "0x087e50cee4f6114119978bb4024a95b77dd39889",
    "BUSD": "0xd1aa16a13fa858a112059e69df6eacbe8994a708",
    "USDC": "0x49d87a2798e33dbe9f514e0fc4db19cbbdaca6f9",
    "PREKEPL": "0xa0a837bd0ef4738ac16cd50dfcfaf14f593b5e70",
    "ETH": "0xd17be08534599013dd4c0a7a76419181c2fd24d4",
    "WMATIC": "0xb5d0b9df238dedf8e5eb8532611f1f20070d2dfb",
    "SwapFactory": "0xf9ee2ccdcd64fcfabb9a55035ac590fb4a478e16",
    "SwapRouter": "0xb391b6ca017a24b77b947186cd9cabe438e98508",
    "WMATIC-USDT": "0xb3ac9b292b21e091ba3b4fcc51fa12d8372d1ba1",
    "KEPL-WMATIC": "0x94e98fc96dd32cdb242ce150ba46b53db6a29223",
    "KEME-WMATIC": "0x72bbcc0da73179fdd812a7d0e5c91f31458df54b",
    "ETH-USDT": "0xbb703c62ede9144ce95cdb7e9eff740aaa7bbab3",
    "SwapOracle": "0x7e389e744694059e64643223445b6fbf0b9c7c45",
    "SwapAggregator": "0x57c559f6aba020074950557675e38244532682b6",
    "KeplerSuit": "0x04deb5f906de1db51b807c2f63a375fffe4d406c",
    "MysteryBox": "0xf3f1e4114868c63aa2c18aedcb9d56149bb1cb20",
    "NFTMarket": "0x636f8bdc2140bc56f4409d0cb9bcb33428b4db40",
    "PoolFactory": "0xcc287f6775ad97e604cb32d7b318c5e6fac83c0c",
    "RewardPool": "0xbf6de728511105cc9a0546671aa04de25b708bea",
    "DepositPool(KEPL-WMATIC)": "0xd9fae4a8ecd4bd79dafb4c88e41122b84a67beb3",
    "Bridge": "0xa1f3568c2e822f91a6e8f0f28c86357a5ab1b3fe",
    "UserClaim": "0x1ae0db8763b7be3ce57977b760ff20464d06bb39"
}