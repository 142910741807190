import React, { useMemo,useEffect} from "react";
import { useLocation } from 'react-router'
import { Routes, Route, Link } from "react-router-dom";
import Home from './pages/Home'
import Market from './pages/Market'
import Origin from './pages/Origin'
import NFT from './pages/NFT'
import NFTdetail from './pages/NFTdetail'
import Mysterybox from './pages/Mysterybox'
import Mynft from './pages/Mynft'
import User from './pages/User'
import FarmOverview from './components/Farm/Overview'
import FarmFarm from './components/Farm/Farm'
import FarmRewards from './components/Farm/Rewards'
import Faq from './pages/Faq'
import Tokenomics from './pages/Tokenomics'
import ApplyVolunteer from './pages/ApplyVolunteer'
import ApplyPartner from './pages/ApplyPartner'
import Download from "./pages/Download";
import Whitelist from "./pages/Whitelist";
import Mint from "./pages/Mint";
import Mintest from "./pages/Mintest";
import News from './pages/News'
import Claim from './pages/Claim'
import Bridge from './components/Bridge/Bridge'
import Layout from './components/layouts/Layout'
import './i18n'
import { ConnectionProvider, WalletProvider as WalletProviderSol} from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { clusterApiUrl } from '@solana/web3.js';
import Bus from './lib/eventBus'
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
  BitKeepWalletAdapter,
  BackpackWalletAdapter
} from '@solana/wallet-adapter-wallets';
import {
  // ConnectionProvider,
  WalletProvider,
  HippoWalletAdapter,
  AptosWalletAdapter,
  HippoExtensionWalletAdapter,
  MartianWalletAdapter,
  FewchaWalletAdapter,
  PontemWalletAdapter,
  SpikaWalletAdapter,
  RiseWalletAdapter,
  FletchWalletAdapter,
  TokenPocketWalletAdapter,
  ONTOWalletAdapter,
  BloctoWalletAdapter,
  SafePalWalletAdapter
} from '@manahippo/aptos-wallet-adapter';
import Airdrop from "./pages/Airdrop";
import Airdropclaim from "./pages/Airdropclaim";
import Mysterydetail from "./pages/Mysterydetail"

function App() {
  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  let {pathname} = useLocation()
  const walletsol = useMemo(
    () => [
      new BackpackWalletAdapter()
        // new PhantomWalletAdapter(),
        // new SlopeWalletAdapter(),
        // new SolflareWalletAdapter(),
        // new TorusWalletAdapter(),
        // new LedgerWalletAdapter(),
        // new SolletWalletAdapter({ network }),
        // new SolletExtensionWalletAdapter({ network }),
        // new BitKeepWalletAdapter(),
    ],
    [network]
  );
  const wallets = useMemo(
    () => [
      // new HippoWalletAdapter(),
      new AptosWalletAdapter(),
      new MartianWalletAdapter(),
      new FewchaWalletAdapter(),
      // new HippoExtensionWalletAdapter(),
      new PontemWalletAdapter(),
      // new SpikaWalletAdapter(),
      new RiseWalletAdapter(),
      // new FletchWalletAdapter(),
      // new TokenPocketWalletAdapter(),
      // new ONTOWalletAdapter(),
      // new BloctoWalletAdapter(),
      // new SafePalWalletAdapter()
    ],
    []
  );
  useEffect(()=> {
    document.title = `KEPLER | ${pathname.replace('/', '')?pathname.replace('/', '').toUpperCase():'HOME'}`
    if (window.innerWidth < 768) {
      Bus.emit('foldChange', false);
    }
  }, [pathname])

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProviderSol wallets={walletsol} autoConnect>
      <WalletProvider wallets={wallets} autoConnect>
        <div className="App">
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/download" element={<Download />} />
              <Route path="/origin" element={<Origin />} />
              <Route path="/market" element={<Market />} />
              <Route path="/NFT" exact={true} element={<NFT />} />
              {/* <Route path="/NFT/:nftId/:tokenId" exact={true} element={<NFT />} /> */}
              <Route path="/NFTDETAIL/:id" exact={true} element={<NFTdetail />} />
              <Route path="/MYSTERYDETAIL/:id" exact={true} element={<Mysterydetail />} />
              <Route path="/Mysterybox/:id" exact={true} element={<Mysterybox />} />
              <Route path="/mynft" element={<Mynft />} />
              <Route path="/profile" element={<User />} />
              <Route path="/farm/overview" element={<FarmOverview />} />
              <Route path="/farm/pools" element={<FarmFarm />} />
              <Route path="/farm/mystaking" element={<FarmFarm />} />
              <Route path="/farm/claim" element={<FarmRewards />} />
              <Route path="/farm/withdraw" element={<FarmRewards />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/apply-volunteer" element={<ApplyVolunteer />} />
              <Route path="/apply-partner" element={<ApplyPartner />} />
              <Route path="/news" element={<News />} />
              <Route path="/bridge" element={<Bridge />} />
              <Route path="/tokenomics" element={<Tokenomics />} />
              <Route path="/nft-whitelist" element={ <Whitelist /> } />
              <Route path="/nft-mint" element={ <Mint /> } />
              <Route path="/nft-mint-test" element={ <Mintest /> } />
              <Route path="/claim" element={ <Claim /> } />
              <Route path="/airdrop" element={ <Airdrop /> } />
              <Route path="/airdrop/claim" element={ <Airdropclaim /> } />
            </Routes>
          </Layout>
        </div>
      </WalletProvider>
      </WalletProviderSol>
   </ConnectionProvider>
  );
}

export default App;
