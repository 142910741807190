import Airnav from "../components/layouts/Airnav"
import './Airdrop.scss'
import { Button, Carousel} from "antd"
import { Link } from "react-router-dom"
import { tokenList } from "../lib/airdrop"
import {queryVariables} from '../contract/methods/airdrop'
import { useEffect, useState} from "react"
import { findNameByAddress, fromUnit, numFormat, toUnit, toWei } from "../lib/util"
import { toFixed } from "accounting"
const Claimprogress = ({info}) => {
    let [refresh, setRefresh] = useState(0)
    let [totalRewardAmount, setTotalRewardAmount] = useState(0)
    let [totalClaimedRewardAmount, setTotalClaimedRewardAmount] = useState(0)
    
    useEffect(async ()=>{
        if(info.type) {
            let config = await queryVariables(info.tokenName)
            console.log(config)
            setTotalRewardAmount(fromUnit(config.totalRewardAmount))
            setTotalClaimedRewardAmount(fromUnit(config.totalClaimedRewardAmount))
        }
        
    },[refresh])
    return (
        <div className="token-item flex flex-center m-b-50">
                             <div className="logo-area">
                             <img src={info.icon} alt="" className="token-logo"/>
                             <img src={require('../assets/images/airdrop/hover.png')} alt="" className="hover"/>
                             </div>
                             <div className="flex flex-column cf flex-1 p-l-34">
                                 <span className="fz-20 fwb">{info.name}</span>
                                 <div className="flex flex-between w100">
                                     <span className="cf">holders claimed</span>
                                     <span className="fz-14">{numFormat(totalClaimedRewardAmount)}/{numFormat(totalRewardAmount) }</span>
                                 </div>
                                 <div className="progress-outter m-t-15 w100 flex flex-between flex-center">
                                     <div className={"progress-inner "+info.className} style={{width:(totalClaimedRewardAmount*100/totalRewardAmount)+'%'}}></div>
                                     <span className="fz-12 progress-text">{(totalClaimedRewardAmount*100/totalRewardAmount).toFixed(2)}%</span>
                                 </div>
                             </div>
                         </div>
    )
}

const Who = () => {
    return (
        <div className="who">
            <img className="flower" src={require('../assets/images/airdrop/flower.svg').default} alt="" />
            <div className="message">
                <Carousel dotPosition="right" autoplay dots={false}>
                    <div className="p-3">
                        <div className="cf flex msg-item flex-between">
                            <span className="flex flex-center fz-16 fw500">
                                <img  className="avatar" src={require('../assets/images/token/KEPL.png')} alt="" />
                                0xc71e1…0e319
                            </span>
                            <span>
                            Claimed 1950.00 pre-KEPL
                            </span>
                        </div>
                    </div>
                    <div className="p-3">
                        <div className="cf flex msg-item flex-between">
                            <span className="flex flex-center fz-16 fw500">
                                <img  className="avatar" src={require('../assets/images/token/KEPL.png')} alt="" />
                                0xc71e1…0e319
                            </span>
                            <span>
                            Claimed 1950.00 pre-KEPL
                            </span>
                        </div>
                    </div>
                </Carousel>
                <Carousel dotPosition="right" autoplay dots={false}>
                    <div className="p-3">
                        <div className="cf flex msg-item flex-between">
                            <span className="flex flex-center fz-16 fw500">
                                <img  className="avatar" src={require('../assets/images/token/KEPL.png')} alt="" />
                                0xc71e1…0e319
                            </span>
                            <span>
                            Claimed 1950.00 pre-KEPL
                            </span>
                        </div>
                    </div>
                    <div className="p-3">
                        <div className="cf flex msg-item flex-between">
                            <span className="flex flex-center fz-16 fw500">
                                <img  className="avatar" src={require('../assets/images/token/KEPL.png')} alt="" />
                                0xc71e1…0e319
                            </span>
                            <span>
                            Claimed 1950.00 pre-KEPL
                            </span>
                        </div>
                    </div>
                </Carousel>
            </div>
        </div>
    )
}
export default () => {
    return (
        <div className="airdrop w100 m-t-0">
            <Airnav/>


            <div className="airdrop-banner p-t-100 w100">
                
                 <div className="airdrop-banner-content flex flex-between">
                     <div className="air-left-part p-t-104">
                         <div className="kepler fwb">Kepler Homes</div>
                         <div className="slogan fwb">
                         an AAA blockchain based NFT Game owned by players.
                         </div>
                         <div className="fz-18 cf m-t-30">
                         Kepler's first airdrop reward will be given to the token holders of 
                         </div>
                         
                         <div className="fz-18 cf fwb pointer ce">
                                LOOKS (LooksRare), APE (Apecoin), and BEND (BendDao)
                         </div>
                         <div className="btns flex flex-between m-t-60 ">
                             <Link to="/airdrop/claim">
                             <Button className="my-button fz-20 color cf air-btn claim-btn fwb">AirDrop Claim</Button>
                             </Link>
                             <a href="https://nft-mint.kepler.homes/nft-whitelist" target="_blank">
                             <Button className="my-button fz-20 cf air-btn white-btn fwb">NFT Whitelist</Button>
                             </a>

                         </div>
                     </div>

                     {/* right-part */}
                     <div className="air-right-part p-t-122">
                         {
                             tokenList.map(item => {
                                    return <Claimprogress info={item}/>
                             })
                         }


                     </div>


                     <img src={require('../assets/images/airdrop/arrow.png')} alt="" className="arrow"/>
                     {/* right-part */}
                     <Who/>


                 </div>

               
                 

            </div>
            <div className="airdrop-info">
                <div className="airdrop-info-content">

                    <div className="metrics">
                        <span className="fz-56 fwb metrics-title">Token Metrics</span>
                        <div className="fz-24 m-t-30">
                            The<span className="fwb ce"> rewarded pre-KEPL can be swaped to KEPL</span>
                            which can be consumed in the Kepler Homes' ecosystem acitivities.
                        </div>
                    </div>
                    <div className="giveaway giveaway-area">
                        <div className="fz-24 fwb giveaway-title">3,000,000 pre-KEPL</div>
                        <div className="fz-18 m-t-5">Airdrop Giveaway</div>
                    </div>
                    
                    <div className="giveaway snapshot">
                        <div className="fz-24 fwb giveaway-title">2022-12-12 12:23:31</div>
                        <div className="fz-18 m-t-5">Snapshot Time</div>
                    </div>
                    <div className="giveaway start">
                        <div className="fz-24 fwb giveaway-title">2022-12-12 12:23:31 am</div>
                        <div className="fz-18 m-t-5">Start Date</div>
                    </div>
                    <div className="giveaway end">
                        <div className="fz-24 fwb giveaway-title">2022-12-12 12:23:32 pm</div>
                        <div className="fz-18 m-t-5">End Date</div>
                    </div>

                    <img className="star star1" width={149} src={require('../assets/images/airdrop/star.png')} alt="" />
                    <img className="star star2" width={60} src={require('../assets/images/airdrop/starmini.png')} alt="" />
                    <img className="star star3" width={40} src={require('../assets/images/airdrop/starmi.png')} alt="" />

                    <div className="question fz-18">
                        Want to know more about the Kepler NFT?
                        The NFT <u className="fwb">whitelist</u> is still open, don`t miss it!

                        <div className="line line1"></div>
                        <div className="line line2"></div>
                        <div className="line line3"></div>
                        <div className="line line4"></div>
                    </div>
                    <div className="footer flex flex-center flex-middle">
                        <img src={require('../assets/images/airdrop/mini-logo.png')} alt="" className="mini-logo"/>
                        <span className="fz-14 m-l-11">Kepler © 2022, All rights reserved</span>
                    </div>

                </div>


                <img src={require('../assets/images/airdrop/lines.png')} alt="" className="w100 lines-img"/>
            </div>

        </div>
    )
}