
import ReactECharts from 'echarts-for-react'
import  * as echarts from 'echarts'
import { t } from 'i18next';
import { numFormat } from '../../lib/util';

export default () => {
    let options = {
        textStyle: {
          fontFamily: "'DINPro-Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif"
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          },
          formatter: function (params) {
              console.log(params)
              let arr = [
                  `<div class="fwb fz-16 m-b-20">${params[0].axisValue}</div>`
              ]
              params.map(item => {
                  arr.push(`<div class="flex flex-center m-b-10">
                  <div class="m-r-5" style="width: 10px;height:10px;background: ${item.color}"></div>
                  ${item.seriesName}
                  
                  <span class="fwb flex-1 tr m-l-20">
                   ${numFormat(item.value.toFixed(0))}
                  </span>
                  
                  </div>`)
              })
              return arr.join('')
          }
        },
        legend: {
          data: ['Allocation','Seed','Private sale','Marketing','Public sale','Fundation','DAO','Token&NFT Staking ','Code Contributor & UGC','Liquidity','Advisors','Team'],
          textStyle: {
              color: '#fff'
          },
          icon: 'rect'
        //   formatter (name) {
        //      return name
        //   }
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: [
              '12/2023(TEG)',
              '01/2024',
              '02/2024',
              '03/2024',
              '04/2024',
              '05/2024',
              '06/2024',
              '07/2024',
              '08/2024',
              '09/2024',
              '10/2024',
              '11/2024',
              '12/2024',
              '01/2025',
              '02/2025',
              '03/2025',
              '04/2025',
              '05/2025',
              '06/2025',
              '07/2025',
              '08/2025',
              '09/2025',
              '10/2025',
              '11/2025',
              '12/2025',
              '01/2026',
              '02/2026',
              '03/2026',
              '04/2026',
              '05/2026',
              '06/2026',
              '07/2026',
              '08/2026',
              '09/2026',
              '10/2026',
              '11/2026',
              '12/2026',
              '01/2027',
              '02/2027',
              '03/2027',
              '04/2027',
              '05/2027',
              '06/2027',
              '07/2027',
              '08/2027',
              '09/2027',
              '10/2027',
              '11/2027',
              '12/2027',
              '01/2028',
              '02/2028',
              '03/2028',
              '04/2028',
              '05/2028',
              '06/2028',
              '07/2028',
              '08/2028',
              '09/2028',
              '10/2028',
              '11/2028',
              '12/2028',
              '01/2029',
              '02/2029',
                ]
          },
        ],
        yAxis: [
          {
            type: 'value',
            // axisLabel: {
            //     formatter: '{value} %'
            // },
            splitLine: {
                show: false
            }
          }
        ],
        series: [
          {
            name: 'Seed',
            color: '#CE8544',
            type: 'line',
            showSymbol: false,
            stack: 'Total',
            areaStyle: {},
            label: {
                show: false
            },
            // emphasis: {
            //   focus: 'series'
            // },
            data: [
              0,
              0,
              0,
              1991111,
              3982222,
              5973333,
              7964444,
              9955556,
              11946667,
              13937778,
              15928889,
              17920000,
              19911111,
              21902222,
              23893333,
              25884444,
              27875556,
              29866667,
              31857778,
              33848889,
              35840000,
              37831111,
              39822222,
              41813333,
              43804444,
              45795556,
              47786667,
              49777778,
              51768889,
              53760000,
              55751111,
              57742222,
              59733333,
              61724444,
              63715556,
              65706667,
              67697778,
              69688889,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
              71680000,
            ]
          },
          {
            name: 'Private sale',
            type: 'line',
            showSymbol: false,
            stack: 'Total',
            color: '#AA3961',
            areaStyle: {},
            label: {
                show: false
            },
            data: [
              0,
              0,
              0,
              3413333,
              6826667,
              10240000,
              13653333,
              17066667,
              20480000,
              23893333,
              27306667,
              30720000,
              34133333,
              37546667,
              40960000,
              44373333,
              47786667,
              51200000,
              54613333,
              58026667,
              61440000,
              64853333,
              68266667,
              71680000,
              75093333,
              78506667,
              81920000,
              85333333,
              88746667,
              92160000,
              95573333,
              98986667,
              102400000,
              105813333,
              109226667,
              112640000,
              116053333,
              119466667,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
              122880000,
            ]
          },
          {
            name: 'Marketing',
            type: 'line',
            showSymbol: false,
            color: '#1D997F',
            stack: 'Total',
            areaStyle: {},
            label: {
                show: false
            },
            data: [
              0.00,
              1194666.67,
              2389333.34,
              3584000.01,
              4778666.68,
              5973333.35,
              7168000.01,
              8362667,
              9557333.35,
              10752000.02,
              11946666.69,
              13141333.36,
              14336000.03,
              15530666.70,
              16725333.37,
              17920000.04,
              19114666.70,
              20309333.37,
              21504000.04,
              22698666.71,
              23893333.38,
              25088000.05,
              26282666.72,
              27477333.39,
              28672000.06,
              29866666.73,
              31061333.40,
              32256000.06,
              33450666.73,
              34645333.40,
              35840000.07,
              37034666.74,
              38229333.41,
              39424000.08,
              40618666.75,
              41813333.42,
              43008000.09,
              44202666.76,
              45397333.42,
              46592000.09,
              47786666.76,
              48981333.43,
              50176000.10,
              51370666.77,
              52565333.44,
              53760000.11,
              54954666.78,
              56149333.45,
              57344000.11,
              58538666.78,
              59733333.45,
              60928000.12,
              62122666.79,
              63317333.46,
              64512000.13,
              65706666.80,
              66901333.47,
              68096000.14,
              69290667,
              70485333,
              71680000,
              71680000,
              71680000
                ]
          },
          {
            name: 'Public sale',
            type: 'line',
            showSymbol: false,
            color: '#9657A6',
            stack: 'Total',
            areaStyle: {},
            label: {
                show: false
            },
            data: [
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
              20480000,
            ]
          },
          {
            name: 'Fundation',
            type: 'line',
            showSymbol: false,
            color: '#085884',
            stack: 'Total',
            label: {
              show: true,
              position: 'top'
            },
            areaStyle: {},
            label: {
                show: false
            },
            data: [
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              2133333.33,
              4266666.67,
              6400000.00,
              8533333.33,
              10666666.67,
              12800000.00,
              14933333.33,
              17066666.67,
              19200000.00,
              21333333.33,
              23466666.67,
              25600000.00,
              27733333.33,
              29866666.67,
              32000000.00,
              34133333.33,
              36266666.67,
              38400000.00,
              40533333.33,
              42666666.67,
              44800000.00,
              46933333.33,
              49066666.67,
              51200000.00,
              53333333.33,
              55466666.67,
              57600000.00,
              59733333.33,
              61866666.67,
              64000000.00,
              66133333.33,
              68266666.67,
              70400000.00,
              72533333.33,
              74666666.67,
              76800000.00,
              78933333.33,
              81066666.67,
              83200000.00,
              85333333.33,
              87466666.67,
              89600000.00,
              91733333.33,
              93866666.67,
              96000000.00,
              98133333,
              100266667,
              102400000,
              102400000,
              102400000
                ]
          },
          {
            name: 'DAO',
            type: 'line',
            showSymbol: false,
            stack: 'Total',
            color: '#212A38',
            label: {
              show: true,
              position: 'top'
            },
            areaStyle: {},
            label: {
                show: false
            },
            data: [
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              948148.15,
              1896296.29,
              2844444.44,
              3792592.59,
              4740740.74,
              5688888.88,
              6637037,
              7585185.18,
              8533333.32,
              9481481.47,
              10429629.62,
              11377777.77,
              12325925.91,
              13274074.06,
              14222222.21,
              15170370.36,
              16118518.50,
              17066666.65,
              18014814.80,
              18962962.94,
              19911111.09,
              20859259.24,
              21807407.39,
              22755555.53,
              23703703.68,
              24651851.83,
              25599999.97,
              26548148.12,
              27496296.27,
              28444444.42,
              29392592.56,
              30340740.71,
              31288888.86,
              32237037.00,
              33185185.15,
              34133333.30,
              35081481.45,
              36029629.59,
              36977777.74,
              37925925.89,
              38874074.04,
              39822222.18,
              40770370.33,
              41718518.48,
              42666666.62,
              43614814.77,
              44562962.92,
              45511111.07,
              46459259.21,
              47407407.36,
              48355555.51,
              49303704,
              50251852,
              51200000,
              51200000,
              51200000
                ]
          },
          {
            name: 'Token&NFT Staking',
            type: 'line',
            showSymbol: false,
            stack: 'Total',
            color: '#823232',
            label: {
              show: true,
              position: 'top'
            },
            areaStyle: {},
            label: {
                show: false
            },
            data: [
              0.00,
              1706666.67,
              3413333.33,
              5120000.00,
              6826666.66,
              8533333.33,
              10240000.00,
              11946666.66,
              15359999.99,
              17066666.66,
              18773333.33,
              20479999.99,
              24319999.99,
              28159999.99,
              31999999.99,
              35839999.99,
              39679999.99,
              43519999.99,
              47359999.99,
              51199999.99,
              55039999.99,
              58879999.99,
              62719999.99,
              66559999.99,
              70399999.99,
              74239999.99,
              78079999.99,
              81919999.99,
              85759999.99,
              89599999.99,
              93439999.99,
              97279999.99,
              101119999.99,
              104959999.99,
              108799999.99,
              112639999.99,
              116479999.99,
              120319999.99,
              124159999.99,
              127999999.99,
              131839999.99,
              135679999.99,
              139519999.99,
              143359999.99,
              147199999.99,
              151039999.99,
              154879999.99,
              158719999.99,
              162559999.99,
              166399999.99,
              170239999.99,
              174079999.99,
              177919999.99,
              181759999.99,
              185599999.99,
              189439999.99,
              193279999.99,
              197119999.99,
              200960000,
              204800000,
              204800000,
              204800000,
              204800000
                ]
          },
          {
            name: 'Code Contributor & UGC',
            type: 'line',
            showSymbol: false,
            stack: 'Total',
            color: '#877564',
            label: {
              show: true,
              position: 'top'
            },
            areaStyle: {},
            label: {
                show: false
            },
            data: [
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              0.00,
              4015686.27,
              8031372.53,
              12047058.80,
              16062745.07,
              20078431.33,
              24094117.60,
              28109804,
              32125490.14,
              36141176.40,
              40156862.67,
              44172548.94,
              48188235.20,
              52203921.47,
              56219607.74,
              60235294.00,
              64250980.27,
              68266666.54,
              72282352.80,
              76298039.07,
              80313725.34,
              84329411.60,
              88345097.87,
              92360784.14,
              96376470.41,
              100392156.67,
              104407842.94,
              108423529.21,
              112439215.47,
              116454901.74,
              120470588.01,
              124486274.27,
              128501960.54,
              132517646.81,
              136533333.07,
              140549019.34,
              144564705.61,
              148580391.87,
              152596078.14,
              156611764.41,
              160627450.68,
              164643136.94,
              168658823.21,
              172674509.48,
              176690195.74,
              180705882.01,
              184721568.28,
              188737254.54,
              192752940.81,
              196768627,
              200784313,
              204800000,
              204800000,
              204800000
              ]
          },
          {
            name: 'Liquidity',
            type: 'line',
            showSymbol: false,
            stack: 'Total',
            color: '#799990',
            label: {
              show: true,
              position: 'top'
            },
            areaStyle: {},
            label: {
                show: false
            },
            data: [
              5120000.00,
              5120000.00,
              5120000.00,
              10240000.00,
              10240000.00,
              10240000.00,
              15360000.00,
              15360000.00,
              15360000.00,
              20480000.00,
              20480000.00,
              20480000.00,
              25600000.00,
              25600000.00,
              25600000.00,
              30720000.00,
              30720000.00,
              30720000.00,
              35840000.00,
              35840000.00,
              35840000.00,
              40960000.00,
              40960000.00,
              40960000.00,
              46080000.00,
              46080000.00,
              46080000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000.00,
              51200000,
              51200000,
              51200000,
              51200000,
              51200000
                ]
          },
          {
            name: 'Advisors',
            type: 'line',
            showSymbol: false,
            stack: 'Total',
            color: '#2E3689',
            label: {
              show: true,
              position: 'top'
            },
            areaStyle: {},
            label: {
                show: false
            },
            data: [
              0.00,
              0.00,
              0.00,
              0.00,
              284160.00,
              568320.00,
              852480.00,
              1136640.00,
              1420800.00,
              1704960.00,
              1989120.00,
              2273280.00,
              2557440.00,
              3144160.00,
              3730880.00,
              4317600.00,
              4904320.00,
              5491039.99,
              6077759.99,
              6664479.99,
              7251199.99,
              7837919.99,
              8424639.99,
              9011359.99,
              9598079.99,
              10184799.99,
              10771519.99,
              11358239.98,
              11944959.98,
              12531679.98,
              13118399.98,
              13705119.98,
              14291839.98,
              14878559.98,
              15465279.98,
              16051999.98,
              16638719.98,
              17225439.97,
              17812159.97,
              18398879.97,
              18985599.97,
              19572319.97,
              20159039.97,
              20745759.97,
              21332479.97,
              21919199.97,
              22505919.97,
              23092639.96,
              23679359.96,
              24266079.96,
              24852799.96,
              25439519.96,
              26026239.96,
              26612959.96,
              27199679.96,
              27786399.96,
              28373119.95,
              28959839.95,
              29546560,
              30133280,
              30720000,
              30720000,
              30720000
                ]
          },
          {
            name: 'Team',
            type: 'line',
            showSymbol: false,
            stack: 'Total',
            color: '#33bad7',
            label: {
              show: true,
              position: 'top'
            },
            areaStyle: {},
            label: {
                show: false
            },
            data: [
              0.00,
              0.00,
              1280186.18,
              2560372.37,
              3840558.55,
              5120744.73,
              6400930.91,
              7681117.10,
              8961303.28,
              10241489.46,
              11521675.64,
              12801861.83,
              14082048.01,
              17335296.01,
              20588544.01,
              23841792.01,
              27095040.01,
              30348288.01,
              33601536.01,
              36854784.01,
              40108032.01,
              43361280.01,
              46614528.01,
              49867776.01,
              53121024.01,
              56374272.01,
              59627520.01,
              62880768.01,
              66134016.01,
              69387264.01,
              72640512.01,
              75893760.01,
              79147008.01,
              82400256.01,
              85653504.01,
              88906752.01,
              92160000.01,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000.00,
              92160000,
              92160000,
              92160000,
              92160000,
              92160000,
                ]
          }
        ]
      };
    return (
        <div className='w100 flex flex-center flex-column p-b-40'>
             <div className="tokenomic-title cf fwb m-b-20">{t('Release Rules')}</div>
            <ReactECharts option={options} style={{width: '100%',maxWidth: '1000px', height: window.innerWidth >= 768 ? '600px' : '600px'}}/> 
        </div>
        )
}