import Web3 from 'web3'
import {erc721ABI} from '../abi/erc721'
import {erc1155ABI} from '../abi/erc1155'
import {bep20ABI} from '../abi/bep20'
import nftmarket from '../testnet/Nftmarket'
import PoolFactory from '../testnet/PoolFactory'
import DepositPool from '../testnet/DepositPool'
import RewardPool from '../testnet/RewardPool'
import Bridge from '../testnet/Bridge'
import { erc20ABI } from '../abi/erc20'
import nft from '../testnet/nft'
import Mysterybox from '../testnet/MysteryBox'
import { toWei } from '../../lib/util'
import {getAddress, getCurAddress}  from '../testnet/address'
import { MaxUint256 } from '@ethersproject/constants'
import { post } from '../../http'
import notification from '../../components/notification'
import getNetworkData, { chainSymbolMap } from '../../wallet/helper/getNetworkDataMain';
import { createProviderController } from '../../wallet/web3/createProviderController'
import store from '../../store'
import BigNumber from 'bignumber.js'
const chain = localStorage.getItem('kepler_chain') || 'ETH'

const httpProviderURL = 'https://data-seed-prebsc-1-s1.binance.org:8545/'
console.log(httpProviderURL)
// const provider = await createProviderController(chain).connect()
export const web3 = new Web3(new Web3.providers.HttpProvider(httpProviderURL))
// if(!web3) {
    
// }

function createWeb3(chain) {
  let httpProviderURL

  if (chain === 'ETH') {
    httpProviderURL = getNetworkData.getETHNetwork().httpProviderURL
  } else if (chain === 'BSC') {
    httpProviderURL = getNetworkData.getBSCNetwork().httpProviderURL
  } else if (chain === 'Polygon') {
    httpProviderURL = getNetworkData.getPolygonNetwork().httpProviderURL
  } else if (chain === 'Avalanche') {
    httpProviderURL = getNetworkData.getAvalancheNetwork().httpProviderURL
  }
  
  return new Web3(new Web3.providers.HttpProvider(httpProviderURL))
}

function createCurWeb3() {
  const chain = store.getState().chain
  return createWeb3(chain)
}

// / 将string转成bytes32
const stringToBytes32 = (s) => {
  let result = web3.utils.fromAscii(s);
  while (result.length < 66) {
      result = result + "0";
  }
  return result;
}
export function balanceOf1155 (contractAddress, address, id) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(erc1155ABI, contractAddress).methods.balanceOf(address, id).call()
}

export function balanceOf (contractAddress, address) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(erc721ABI, contractAddress).methods.balanceOf(address).call()
}
export function getTokenId (contractAddress, address, index) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(erc721ABI, contractAddress).methods.tokenOfOwnerByIndex(address, index).call()
}
export function getTokenURI (contractAddress, tokenId) {
  const web3 = createCurWeb3()
  return new web3.eth.Contract(erc721ABI, contractAddress).methods.tokenURI(tokenId).call()
}
