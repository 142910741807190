import React, { useState, useEffect, useRef } from 'react';
import './Home.scss';
import LocomotiveScroll from 'locomotive-scroll';
import 'locomotive-scroll/dist/locomotive-scroll.min.css'


import iconWin from '../assets/images/home/icon-windows.svg'
import iconMac from '../assets/images/home/icon-mac.svg'
import Grid from '../components/Grid'
import classnames from 'classnames'
import play from '../assets/images/home/play.png'
import playCircle from '../assets/images/home/play-circle.png'
import playText from '../assets/images/home/play-text.png'
import bg1 from '../assets/images/home/bg1.png'
import bg2 from '../assets/images/home/bg2.png'
import bg3 from '../assets/images/home/bg3.png'
import about1 from '../assets/images/home/about1.png'
import about2 from '../assets/images/home/about2.png'
import about3 from '../assets/images/home/about3.png'
import features from '../assets/images/home/features.png'
import news1 from '../assets/images/home/news1.png'
import news2 from '../assets/images/home/news2.png'
import news3 from '../assets/images/home/news3.png'
import news4 from '../assets/images/home/news4.png'
import characters1 from '../assets/images/home/characters1.png'
import characters2 from '../assets/images/home/characters2.png'
import characters3 from '../assets/images/home/characters3.png'
import charactersActive from '../assets/images/home/characters-active.png'
import man1 from '../assets/images/home/man1.png'
import man2 from '../assets/images/home/man2.png'
import man3 from '../assets/images/home/man3.png'
import aptos from '../assets/images/ConnectWallet/Aptos.png'
import binance from '../assets/images/ConnectWallet/binance.png'
import base from '../assets/images/ConnectWallet/BASE.svg'
import social from '../assets/images/ConnectWallet/social.svg'
import xbot from '../assets/images/ConnectWallet/xbot.png'
import arbitrum from '../assets/images/ConnectWallet/arbitrum.jpg'
import aboutVideo from '../assets/about.mp4'
import loopVideoWebm from '../assets/loop_new.webm'
import loopVideoMp4 from '../assets/loop_new.mp4'
import loopPoster from '../assets/loop-poster_new.jpg'
import aboutPoster from '../assets/about-poster.jpg'
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import Header from '../components/layouts/Header'
import Footer from '../components/layouts/Footer'
import {post} from '../http'
import notification from '../components/notification'
import {Input, Button} from 'antd'
import {useTranslation} from 'react-i18next'
// import { ReactSVG } from 'react-svg'
import { get } from '../http'
import roadmap from '../lib/roadmap';
import { Link } from 'react-router-dom'

function Home() {
  const page = useRef()
  const playDom = useRef()
  const [roadmapIndex, setRoadmapIndex] = useState(3)
  const [jobIndex, setJobIndex] = useState(0)
  const [isPalyShow, setIsPlayShow] = useState(false)
  const [hasBg, setHasBg] = useState(false)
  const [email, setEmail] = useState('')
  let { t ,i18n} = useTranslation()
  const [isRegistering, setIsRegistering] = useState(false)
  const [isLoadingNews, setIsLoadingNews] = useState(false)
  const [newsList, setNewsList] = useState([])

  useEffect(() => {
    setIsLoadingNews(true)
    get('/api/news', {
      limit: 4,
      skip: 0
    }).then(res => {
      setIsLoadingNews(false)
      setNewsList(res.data.news)
      
      const scroll = new LocomotiveScroll({
        el: page.current,
        smooth: true
      });

      scroll.on('scroll', (args) => {
        setHasBg(args.scroll.y > 0)
        // Get all current elements : args.currentElements
        // console.log(args.currentElements)
        // if(typeof args.currentElements['intro'] === 'object') {
        //   setIsPlayShow(true)
        // } else {
        //   setIsPlayShow(false)
        // }
      });

      var box = document.getElementById("box");
      //绑定鼠标移动事件
      document.onmousemove = function(event){
        /*获取到鼠标的坐标 */
        var left = event.clientX;
        var top = event.clientY;
        //设置div的偏移量
        // playDom.current.style.transform = `translate(${left - 150}px, ${top - 150}px)`;
      };
      
      console.log(window.innerWidth)
    })
  
    return () => {

    }
  }, [])

  let loadNews = (page) => {
    
  }

  const jobMap = [
    {
      name: "WARRIOR",
      occupation: "MELEE OCCUPATION",
      desc: "Carrying a large sword, physically strong, good at close combat and pulling each other together, they are the main vanguard of the team. Gutsy is good at using physical attacks to inflict damage on hostile targets. To face Gutsy, you will need to improve your own physical armor to fight against them."
    },
    {
      name: "SHOOTER",
      occupation: "REMOTE OCCUPATION",
      desc: "Armed with a variety of guns and ammunition, with outstanding eyesight and concealment abilities, the gunman has the skill to inflict a fatal blow on an enemy at a critical moment. Gunmen are more adept at using blast attacks to inflict damage on hostile targets. When facing a gunman, you need to increase your blast armor to fight against him."
    },
    {
      name: "INVOKER",
      occupation: "REMOTE OCCUPATION",
      desc: "The Magic Guide uses the back of the conduction core to absorb the surrounding natural energy. They use the conduction cane in different ways to cast a variety of energy attacks. They are especially good at dealing with a variety of unexpected situations, and are usually the brains of the team. Magic guides are good at using energy attacks to cause damage to hostile targets. Facing the magic guide, you need to improve your own energy armor to fight with."
    },
  ]

  const handleRegister = () => {
    if (isRegistering) {
      return
    }

    if (!/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(email)) {
      notification.error({
        message: t('Please enter a valid email address'),
      });
      return
    }

    setIsRegistering(true)
    post('/api/community/subscribe', {
      email,
    }).then(res => {
      setIsRegistering(false)
      notification.success({
        message: t('Success'),
      });
    }).catch(err => {
      setIsRegistering(false)
      notification.error({
        message: t('Fail'),
      });
    })
  }

  return (
    <div ref={page} data-scroll-container className="Home">
      <Grid />
      
      <Header hasBg={hasBg} />
      
      <main>
        <div className="intro">
          <div data-scroll-section className="intro-front intro-title layer-front" data-scroll data-scroll-id="intro" >
            <div className="intro-title-main">
              <div>Welcome to</div>
              <div>Kepler Homes</div>
            </div>
            <div className="intro-title-sub">Play, Earn and Trade in the blockchain based NFT Game owned by players.</div>
            <div className="btn-group">
              {/* <div className="btn-for-win"><div className="comingsoon">Coming soon</div><ReactSVG src={iconWin} className="icon-win" /><div className="flex flex-column"><div>For Windows</div></div></div> 
              <div className="btn-for-mac"><div className="comingsoon">Coming soon</div><ReactSVG src={iconMac} className="icon-mac" /><div className="flex flex-column"><div>For Mac</div></div></div>*/}
            {/* <a href="https://presale-arbitrum.kepler.homes" target="_blank" className="btn-nft-mint">
              <img src={arbitrum} alt="" style={{height:'60%'}}/>&nbsp;
              Arbitrum Presale</a> */}
              <a href="https://xbot.bi/" target="_blank" className="btn-nft-mint">
                 <img src={xbot} alt="" style={{height:'60%'}}/>&nbsp;
                 Solana xBot</a>

                 
              <a href="https://airdrop.xbot.bi" target="_blank" className="btn-nft-mint">
              <img src={xbot} alt="" style={{height:'60%'}}/>&nbsp;xBot Airdrop for $gKPR </a>
                 
                 </div>
            <div className="btn-group" style={{flexDirection: 'row'}}>
              {/* <a href="https://passport-aptos.kepler.homes/" target="_blank" className="btn-nft-whitelist">
              <img src={aptos} alt="" style={{height:'60%'}}/>&nbsp;Aptos Passport WL
              </a> */}
             </div>

            <a href="https://www.youtube.com/@keplerhomes" target="_blank" className={classnames('play', {"show": isPalyShow})}>
              <img className="play-circle" src={playCircle} />
              <img className="play-text" src={playText} />
              <img className="btn-play" src={play} />
            </a>
          </div>
          <div className="intro-back layer-back" data-scroll-section>
            <div className="intro-bg__picture" data-scroll data-scroll-speed="-2">
              {/* <img src={bg1} alt="" /> */}
              <video className="loop-video" poster={loopPoster} playsInline loop autoPlay muted>
                <source src={loopVideoWebm} type="video/webm" />
                <source src={loopVideoMp4} type="video/mp4" />
              </video>
              
              <div className="intro-top"></div>
              <div className="intro-bottom"></div>
            </div>
          </div>
          <div data-scroll-section className="intro-scroll layer-front"><span className="intro-scroll__track"></span><span className="intro-scroll__bar"></span><span className="intro-scroll__arrow"></span></div>
        </div>

        <div className="about">
          <div className="about-front layer-front" data-scroll-section>
            <div className="about-item">
              <div className="about-item-left">
                <div className="about-item__picture bracket-wrap">
                  <img src={about1} alt="" />
                  {/* <div className="bracket">
                    <div className="bracket-tl"></div>
                    <div className="bracket-tr"></div>
                    <div className="bracket-br"></div>
                    <div className="bracket-bl"></div>
                  </div> */}
                </div>
              </div>
              <div className="about-item-right">
                <h3>Project “Adam”</h3>
                <p>In 2113, the Earth's resources are being depleted at a speed visible to the naked eye. The human race has less than a hundred years left to survive on Earth. Escaping from Earth and flying to outer space has become the only hope for human survival. The "Adam Project" was born in this context.</p>
                <p>After the failure of the first Adam Project due to problems with the "atmospheric environment generation equipment" all teams were lost in the second Adam program. In 2170, the third Adam project launched, with the destination of the Kepler Galaxy.</p>
              </div>
            </div>
            <div className="about-item">
              <div className="about-item-left">
                <h3>Titania</h3>
                <p>In the Third Adam Project, Titania, the mothership, leaves for the target planet with the rest of the fleet.</p>
                <p>After six months of travel, the Armada finally approaches its destination: Kepler-22b.</p>
                <p>The advanced units, including Zeus II, would first land on the planet to explore and set up camps. During the landing process, the advance party was disturbed by an ion storm, and the fleet was scattered, each forced to land in a different area.</p>
              </div>
              <div className="about-item-right">
                <div className="about-item__picture bracket-wrap">
                  <img src={about2} alt="" />
                  {/* <div className="bracket">
                    <div className="bracket-tl"></div>
                    <div className="bracket-tr"></div>
                    <div className="bracket-br"></div>
                    <div className="bracket-bl"></div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="about-item">
              <div className="about-item-left">
                <div className="about-item__video bracket-wrap">
                  {/* <img src={about3} alt="" /> */}

                  <video className="about-video" poster={aboutPoster} playsInline loop autoPlay muted src={aboutVideo}></video>
                  {/* <div className="bracket">
                    <div className="bracket-tl"></div>
                    <div className="bracket-tr"></div>
                    <div className="bracket-br"></div>
                    <div className="bracket-bl"></div>
                  </div> */}
                </div>
              </div>
              <div className="about-item-right">
                <h3>Spring Action</h3>
                <p>The player is a member of the Zeus II fleet. After a forced landing in the wilderness, contact with the mothership Titania is restored. Unfortunately, the signal is unstable and often disconnected.</p>
                <p>Scientists assess that it may be related to the ion storm that the advance team encountered during their landing. The Titanian Fleet senior management immediately developed the "Spring Action" plan.</p>
                <p>The advanced units need to find a way to make the mothership and the rest of the fleet land safely.</p>
              </div>
            </div>
          </div>
          <div className="about-back layer-back" data-scroll-section>
            <div className="planet__picture" data-scroll data-scroll-speed="-2">
              <img src={bg2} alt="" />
              <div className="planet-top"></div>
              <div className="planet-bottom"></div>
            </div>
          </div>
          
        </div>

        <div data-scroll-section className="characters">
          <div className="characters-title">CHARACTERS</div>
          <div className="characters-swiper-container">
            <Swiper
              modules={[Navigation]}
              navigation
              spaceBetween={window.innerWidth >= 768 ? 60 : 11}
              slidesPerView={3}
              onSlideChange={(swiper) => {
                setJobIndex(swiper.realIndex)
              }}
              onSwiper={(swiper) => console.log(swiper)}
              loop
              centeredSlides={true}
            >
              <SwiperSlide>
                <div className="characters-item">
                  <img className="characters-item-image characters-item-image1" src={characters1} alt="" />
                  <img className="characters-item-active" src={charactersActive} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className="characters-item">
                  <img className="characters-item-image characters-item-image2" src={characters2} alt="" />
                  <img className="characters-item-active" src={charactersActive} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="characters-item">
                  <img className="characters-item-image characters-item-image3" src={characters3} alt="" />
                  <img className="characters-item-active" src={charactersActive} alt="" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        

        <div data-scroll-section className="warrior">
          <div className="warrior-left">
            <img src={man1} alt="" style={{"display": jobIndex === 0 ? "block" : "none"}} />
            <img src={man2} alt="" style={{"display": jobIndex === 1 ? "block" : "none"}} />
            <img src={man3} alt="" style={{"display": jobIndex === 2 ? "block" : "none"}} />
          </div>
          <div className="warrior-right">
            <div className="warrior-right-title">
              <p>{jobMap[jobIndex].name}</p>
              <p>{jobMap[jobIndex].occupation}</p>
            </div>
            <div className="warrior-right-content">{jobMap[jobIndex].desc}</div>
          </div>
        </div>

        <div className="bg-wrapper">
          <div className="layer-front">
            <div data-scroll-section className="features">
              <div className="features-title">FEATURES</div>
              <div className="features-picture">
                <img className="features-picture__image" src={features} alt="" />
              </div>
              <div className="features-circle -circle01">
                <div className="features-circle__line"></div>
              </div>
              <div className="features-circle -circle02">
                <div className="features-circle__line"></div>
              </div>
              <div className="features-circle -circle03">
                <div className="features-circle__line"></div>
              </div>
              <div className="features-item -item01">
                <div className="features-item-title">Cross-chain</div>
                <div className="features-item-desc">The currencies and props in the game will be mapped on four different chains, ETH, BSC, AVAX, SOL and Aptos. By becoming tokens and NFT assets on different chains; they can be transferred between each chain using cross-chain technology through bridges.</div>
              </div>
              <div className="features-item -item02">
                <div className="features-item-title">Macro World View</div>
                <div className="features-item-desc">Based on universe exploration as a background, the Kepler game will open different styles of maps and gameplay one after another, this will have new game experiences, and sustainability.</div>
              </div>
              <div className="features-item -item03">
                <div className="features-item-title">Sandbox Game</div>
                <div className="features-item-desc">Players can freely choose the direction they want,and can decide the plot and story line,and can participate in the making of the game by submitting their own designs for items or enemies or looks or stories that will be voted to adde to the game.</div>
              </div>
            </div>

            <div data-scroll-section className="roadmap">
              <div className="container roadmap-front">
                <div className="roadmap-title">ROADMAP</div>
                <div className="roadmap-title-sub">This timeline details our funding and development goals.</div>
                <div className="roadmap-timeline">
                  <div className="roadmap-timeline-date-list">
                  {
                        roadmap.map((item, index) => {
                          return (
                            <div className={classnames("roadmap-timeline-date-item", {"active": roadmapIndex === index})} onMouseOver={_ => setRoadmapIndex(index)}>{item.name}</div>
                          )
                        })
                      }
                  </div>
                  <div className="roadmap-timeline-swiper-container">
                    <Swiper
                      modules={[Navigation]}
                      navigation
                      onSlideChange={(swiper) => {
                        setRoadmapIndex(swiper.realIndex)
                      }}
                      onSwiper={(swiper) => console.log(swiper)}
                      loop
                    >
                      {
                        roadmap.map(item => {
                          return (
                            <SwiperSlide key={item.name}>
                              <div className="roadmap-timeline-date-item">{item.name}</div>
                            </SwiperSlide>
                          )
                        })
                      }
                    </Swiper>
                  </div>
                  <div className="roadmap-timeline-content">
                    {
                       roadmap.map((item, index) => {
                        return (
                          <div key={index} style={{display: roadmapIndex == index ? 'block' : 'none' }}>
                          {item.content}
                        </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>

            <div data-scroll-section className="gallery">
                <div className="gallery__list">
                  {
                    newsList.map(news => {

                      return (
                        <a className="gallery__list-item" href={news.linkUrl} target="_blank">
                          <div className="gallery__list-picture bracket-wrap" data-scroll=""><img className="gallery__list-picture__image" src={'https://api.kepler.homes' + news.banner} alt=""/>
                            <div className="bracket">
                              <div className="bracket-tl"></div>
                              <div className="bracket-tr"></div>
                              <div className="bracket-br"></div>
                              <div className="bracket-bl"></div>
                            </div>
                          </div>
                          <div className="gallery__list-info">
                            <p className="gallery__list-info__text">{news.title}</p>
                            <p className="gallery__list-info__date">{news.updatedAt.split(' ')[0]}</p>
                          </div>
                        </a>
                      )
                    })
                  }
                </div>
            </div>
          </div>

          <div className="bg-back layer-back" data-scroll-section>
            <div className="planet__picture" data-scroll data-scroll-speed="-2">
              <img src={bg3} alt="" />
              <div className="planet-top"></div>
              <div className="planet-bottom"></div>
            </div>
          </div>
        </div>

        <div data-scroll-section className="register-email">
          <div className="register-email-title">
            <div>Are you ready</div>
            <div>to start your mission?</div>
          </div>
          <div className="register-email-input">
            <Input type="text" placeholder="Email Address" onChange={e => setEmail(e.target.value)}/>
            <Button className="btn-register" onClick={_ => handleRegister()} loading={isRegistering}>REGISTER</Button>
          </div>
        </div>
      
      
      </main>
      <Footer />
    </div>
  );
}

export default Home;
