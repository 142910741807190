export default {
    "KEPL": "0x000aa5384e9d90a6cd1d2d4ce3ec605570f7bbbf",
    "PreKEPL": "0x6eb376d9bc067cdad77041ba73d2de0ce2b70f79",
    "USDT": "0xdac17f958d2ee523a2206206994597c13d831ec7",
    "BUSD": "0x4fabb145d64652a948d72533023f6e7a623c7c53",
    "USDC": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    "MATIC": "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
    "WETH": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    "Airdrop(APE)": "0xb230d491d58133c9c71095eaafe346e09fe31f5e",
    "Airdrop(LOOKS)": "0x0548939efa5b84e4b0b695cd9e8facbb5a3de15f",
    "Airdrop(BEND)": "0xdd17d78c1d996d133367f738729a49277d5ef3d3",
    "Airdrop(AXS)": "0x601902c06c9fff15e40c10e6e869642e9826c69e",
    "Airdrop(SAND)": "0xaf45e788f1cf7ea05b456a38d6d2b5e1d29a4a6b",
    "Airdrop(WRLD)": "0xd7ebc0e785c75bef0c9a8da4c0bc373288720230",
    "ChainlinkOracle": "0xe47c5561b1688e3669cca2dcd55be5abf9ec3887",
    "KeplerSuit": "0x49b6c26e7e4aa03037c1505c1d65b063ec26364b",
    "MysteryBox": "0x8df66d1171e4c69b1451b295f565e40677597075"
}